import _isNaN from "lodash/isNaN";
import _toUpper from "lodash/toUpper";

export const localeSort = (a: string, b: string): number => {
  const c = Number.isFinite(a);
  const d = Number.isFinite(b);
  return (c !== d && Number(d) - Number(c))
  || (c && d ? Number(a) - Number(b) : a.localeCompare(b, [ "en", "zh-Hans-CN" ], { sensitivity: "accent" }));
};

export const targetSort = (a: string, b: string): number => {
  const aMatch = (a ?? "").match(/^(\d*)(.*)$/);
  const bMatch = (b ?? "").match(/^(\d*)(.*)$/);
  const aNumber = Number(aMatch?.[1]);
  const bNumber = Number(bMatch?.[1]);
  const aNumberIsNan = _isNaN(aNumber);
  const bNumberIsNan = _isNaN(bNumber);
  if (aNumberIsNan && !bNumberIsNan) return 1;
  if (!aNumberIsNan && bNumberIsNan) return -1;
  if (!aNumberIsNan && !bNumberIsNan) {
    if (aNumber !== bNumber) return aNumber - bNumber;
  }
  const aString = _toUpper(aMatch?.[2]);
  const bString = _toUpper(bMatch?.[2]);
  // 萨仁靶
  if (aString.startsWith("-") || bString.startsWith("-")) {
    return Math.abs(Number(aString)) - Math.abs(Number(bString));
  }
  if (aString < bString) {
    return -1;
  }
  if (aString > bString) {
    return 1;
  }
  return 0;
};
