// 比赛注册信息

import dayjs, { Dayjs } from "dayjs";

import { transcodeOssUrl } from "../oss";

import { MatchGroupType } from "./MatchGroup";
import Team from "./Team";
import { DEFAULT_AVATAR } from "./User";

export enum MatchRegistryVerificationStatus {
  PENDING = 0,
  VERIFIED = 1,
  FAILED = 2,
}

export const MatchRegistryChecks: Array<{
  key: "equipmentCheck" | "releaseCheck" | "arrowCheck" | "directoryCheck";
  text: string;
  shortText: string;
}> = [
  { key: "equipmentCheck", text: "器材(弓)", shortText: "器" },
  { key: "releaseCheck", text: "护指/撒放器", shortText: "撒" },
  { key: "arrowCheck", text: "箭", shortText: "箭" },
  { key: "directoryCheck", text: "号码布", shortText: "号" },
];

export class MatchRegistry {
  rawData: Record<string, any>;

  registrationId: string;

  userId: string;

  userName: string | null;

  userGender: number | null;

  userCellphone: string | null;

  userIdNumber: string | null;

  userAvatarUrl?: string;

  userRegisteredResidence: string | null;

  userBirthday: Dayjs | null;

  verificationStatus: MatchRegistryVerificationStatus;

  experience: string | null;

  teamId: string | null;

  teamName: string | null;

  team: Team | null;

  clubName: string | null;

  teamNameOrClubName: string | null;

  clubId: string | null;

  groupId: string | null;

  matchId: string | null;

  qualifyingTargetNo: number | null;

  qualifyingTargetLocation: string | null;

  feePaid: boolean;

  paymentScreenshotUrl: string | undefined;

  idPhotoUrl: string | undefined;

  responsibilityPledgeUrl: string | undefined;

  inBlacklist: boolean;

  teamLeader: string | null;

  registeredResidence: string | null;

  competitionExperience: string | null;

  groupType: MatchGroupType;

  qualifyingPosition: string;

  equipmentCheck: MatchRegistryVerificationStatus;

  releaseCheck: MatchRegistryVerificationStatus;

  arrowCheck: MatchRegistryVerificationStatus;

  directoryCheck: MatchRegistryVerificationStatus;

  groupName: string | null;

  get registrationIdSortable(): number {
    return parseInt(this.registrationId, 10);
  }

  constructor(rawData: Record<string, any>) {
    this.rawData = rawData;
    this.registrationId = String(rawData.signId);
    this.userId = String(rawData.userId);
    this.userName = rawData.name;
    this.teamId = rawData.teamId != null ? String(rawData.teamId) : null;
    this.teamName = rawData.teamName;
    this.team = rawData?.teamId ? new Team({
      id: rawData.teamid,
      name: rawData.teamName,
      logo: rawData.teamLogo,
    }) : null;
    this.clubId = rawData.clubId;
    this.clubName = rawData.clubFullName;
    this.teamNameOrClubName = rawData.teamName || rawData.clubFullName;
    this.userGender = Number(rawData.sex);
    this.userCellphone = rawData.phone;
    this.userIdNumber = rawData.idNo;
    this.userAvatarUrl = transcodeOssUrl(rawData.avatorOssUrl) || DEFAULT_AVATAR;
    this.groupId = String(rawData.gameGroupId);
    this.matchId = String(rawData.gameId);
    this.qualifyingTargetNo = rawData.qualifyingTargetNo == null ? null : Number(rawData.qualifyingTargetNo);
    this.qualifyingTargetLocation = rawData.qualifyingTargetLocation == null
      ? null : String(rawData.qualifyingTargetLocation);
    this.feePaid = Boolean(rawData.payConfirm);
    this.paymentScreenshotUrl = transcodeOssUrl(rawData.payPic);
    this.idPhotoUrl = transcodeOssUrl(rawData.idPhoto);
    this.responsibilityPledgeUrl = transcodeOssUrl(rawData.responsibilityPledge);
    this.experience = rawData.competitionExperience;
    this.userRegisteredResidence = rawData.registeredResidence;
    this.userBirthday = dayjs(rawData.birthday);
    this.inBlacklist = Boolean(rawData.inBlacklist);
    this.verificationStatus = rawData.verifyIdFlag;
    this.teamLeader = rawData.teamLeader;
    this.registeredResidence = rawData.registeredResidence;
    this.competitionExperience = rawData.competitionExperience;
    this.groupType = rawData.groupType as MatchGroupType;
    this.groupName = rawData.customName || rawData.groupName || rawData?.gameGroup?.name;
    const { qualifyingTargetNo, qualifyingTargetLocation } = this.rawData || {};
    this.qualifyingPosition = ![ null, undefined ].includes(qualifyingTargetNo)
      ? `${qualifyingTargetNo}${qualifyingTargetLocation}`
      : "";

    this.equipmentCheck = rawData.equipmentCheck as MatchRegistryVerificationStatus;
    this.releaseCheck = rawData.fingerCheck as MatchRegistryVerificationStatus;
    this.arrowCheck = rawData.arrowCheck as MatchRegistryVerificationStatus;
    this.directoryCheck = rawData.directoryCheck as MatchRegistryVerificationStatus;
  }

  get userGenderHumanReadable(): string {
    return this.userGender === 0 ? "男" : "女";
  }

  get confirmationCode(): string {
    return [
      this.matchId ?? "X",
      this.groupId ?? "X",
      this.userId ?? "X",
      this.registrationId ?? "X",
    ].join("-");
  }

  static decodeConfirmationCode(code: string): {
    matchId: string;
    groupId: string;
    userId: string;
    id: string;
  } | undefined {
    const regex = /^(\d+)-(\d+)-(\d+)-(\d+)$/g;
    const regexResult = regex.exec(code);
    if (regexResult == null) return undefined;
    return {
      matchId: regexResult[ 1 ],
      groupId: regexResult[ 2 ],
      userId: regexResult[ 3 ],
      id: regexResult[ 4 ],
    };
  }

  static decodeStaffConfirmationCode(code: string): {
    matchId: string;
    userId: string;
    id: string;
  } | undefined {
    const regex = /^(\d+)-(\d+)-(\d+)$/g;
    const regexResult = regex.exec(code);
    if (regexResult == null) return undefined;
    return {
      matchId: regexResult[ 1 ],
      userId: regexResult[ 2 ],
      id: regexResult[ 3 ],
    };
  }

  get confirmationQrDataObject(): {[key: string]: string | undefined} {
    return {
      matchId: this.matchId ?? undefined,
      groupId: this.groupId ?? undefined,
      userId: this.userId ?? undefined,
      id: this.registrationId ?? undefined,
    };
  }

  static decodeConfirmationQrData(code: string): {
    matchId: string;
    groupId: string;
    userId: string;
    id: string;
  } | undefined {
    try {
      const originData = JSON.parse(code);
      const data = {
        ...originData,
        id: originData.id ?? originData.registrationId,
      };
      if (data.matchId == null || data.groupId == null || data.userId == null || data.id == null) {
        return undefined;
      }
      return data;
    } catch (e) {
      return undefined;
    }
  }

  static decodeStaffConfirmationQrData(code: string): {
    matchId: string;
    userId: string;
    id: string;
  } | undefined {
    try {
      const data = JSON.parse(code);
      if (data.matchId == null || data.userId == null || data.id == null) {
        return undefined;
      }
      return data;
    } catch (e) {
      return undefined;
    }
  }

  get teamOrClubName(): string | null {
    return this.teamName ?? this.clubName;
  }

  get userAge(): number {
    return dayjs().diff(dayjs(this.userBirthday), "year");
  }
}

export type MatchRegistryParams = {
  groupId?: string;
  userName?: string;
  userGender?: number;
  userCellphone?: string;
  userIdNumber?: string;
  userBirthday?: Date;
  userRegisteredResidence?: string;
  verificationStatus?: MatchRegistryVerificationStatus;
  experience?: string;
  teamId?: string;
  teamLeader?: string;
  qualifyingTargetNo?: number;
  qualifyingTargetLocation?: string;
  feePaid?: boolean;
}

export const mapMatchRegistryParamsToServerParams = (params: MatchRegistryParams): any => ({
  gameGroupId: params.groupId,
  name: params.userName,
  sex: params.userGender,
  teamId: params.teamId,
  teamLeader: params.teamLeader,
  phone: params.userCellphone,
  idNo: params.userIdNumber,
  qualifyingTargetNo: params.qualifyingTargetNo,
  qualifyingTargetLocation: params.qualifyingTargetLocation,
  registeredResidence: params.userRegisteredResidence,
  competitionExperience: params.experience,
  verifyIdFlag: params.verificationStatus,
  payConfirm: params.feePaid ? 1 : 0,
  birthday: params.userBirthday == null
    ? undefined
    : dayjs(params.userBirthday).format("YYYY-MM-DD"),
});

export type MatchRegistryCheckParams = {
  equipmentCheck?: MatchRegistryVerificationStatus;
  releaseCheck?: MatchRegistryVerificationStatus;
  arrowCheck?: MatchRegistryVerificationStatus;
  directoryCheck?: MatchRegistryVerificationStatus;
}

export const mapMatchRegistryCheckParamsToServerParams = (params: MatchRegistryCheckParams): any => ({
  equipmentCheck: params.equipmentCheck,
  fingerCheck: params.releaseCheck,
  arrowCheck: params.arrowCheck,
  directoryCheck: params.directoryCheck,
});

export default MatchRegistry;
