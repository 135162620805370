// 比赛记分员

import User from "./User";

export class MatchScorer {
  rawData: Record<string, any>;

  user: User | undefined;

  constructor(rawData: Record<string, any>) {
    this.rawData = rawData;
    this.user = rawData.userId == null ? undefined : new User(rawData);
  }
}

export default MatchScorer;
