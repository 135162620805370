import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";

import MatchKnockoutPhase from "./MatchKnockoutPhase";
import Team from "./Team";
import User from "./User";

const getScoreList = (scoreString = ""): Array<number> => {
  // MQTT may return emtpy string
  if (_isEmpty(scoreString)) return [];
  return (scoreString?.split(",") ?? []).map((score) => Number(score));
};

const getTotalScore = (rawData: any, isRound: boolean): number => {
  let scoreList: Array<number> = [];
  if (isRound) {
    // 环胜
    scoreList = getScoreList(rawData?.setRings);
    // 环胜附加赛不计入总分
    const extraFlagIndex = rawData?.knockoutScoreList?.findIndex((it) => it.extraFlag === 1);
    if (extraFlagIndex !== -1) {
      scoreList = scoreList.slice(0, extraFlagIndex);
    }
  } else {
    // 局胜
    scoreList = getScoreList(rawData?.setScore);
  }
  return scoreList.reduce((pre, cur) => pre + cur, 0);
};

enum WinFlag {
  LOSE = 0,
  DRAW = 1,
  WIN = 2,
}

export default class KnockoutScore {
  rawData: Record<string, any>;

  isRound: boolean;

  finalRound: number;

  seq: number;

  playoff: any;

  user: User | undefined;

  scoreList: Array<number>;

  totalScore: number;

  winFlag: number | undefined | null;

  extraFlag: boolean;

  waiveFlag: boolean;

  fullPosition: string;

  phases: Array<MatchKnockoutPhase>;

  team: Team | undefined;

  teamMember?: string;

  clubName: string;

  teamNameOrClubName: string;

  // isTarget 是否是生成站位图
  constructor(rawData: Record<string, any>, isRound: boolean, isTarget = false) {
    const extraFlag = Boolean(rawData.extraFlag);

    this.rawData = rawData;
    this.isRound = isRound;
    this.finalRound = rawData.finalRound;
    this.seq = rawData.seq;
    this.playoff = null;
    this.user = rawData?.userId ? new User(rawData) : undefined;
    this.team = rawData?.teamId ? new Team({
      id: rawData.teamid,
      name: rawData.teamName,
      logo: rawData.teamLogo,
    }) : undefined;
    this.scoreList = getScoreList(rawData?.setRings);
    this.totalScore = getTotalScore(rawData, isRound);
    this.winFlag = rawData.winFlag;
    this.extraFlag = extraFlag;
    this.waiveFlag = rawData.waiveFlag === 1; // 1弃权
    this.fullPosition = ![ null, undefined ].includes(rawData?.targetNo)
      ? `${rawData.targetNo}${rawData.targetLocation || ""}` : "";
    // 对阵图轮空不显示靶位
    if (!isTarget && !this.user?.userId) {
      this.fullPosition = "";
    }
    this.phases = _map(rawData.knockoutScoreList, (it) => new MatchKnockoutPhase(it));
    this.teamMember = rawData?.mixedGroupUserList?.map((it) => it.name).join("、");
    this.clubName = rawData?.clubShortName || rawData?.clubFullName || "";
    this.teamNameOrClubName = rawData.teamName || this.clubName;
  }

  get outcomeHumanReadable(): string {
    switch (this.winFlag) {
    case WinFlag.DRAW: return "平局";
    case WinFlag.WIN: return "胜方";
    case WinFlag.LOSE: return "负方";
    default: return "-";
    }
  }
}
