import Phase from "@ac/services/model/Phase";
import { knockoutIdToRoundId } from "@ac/services/utils/id";

export class MatchKnockoutPhase extends Phase {
  roundId: string;

  // eslint-disable-next-line no-useless-constructor
  constructor(rawData: Record<string, any>) {
    super(rawData);
    this.roundId = knockoutIdToRoundId(rawData.knockoutId);
    this.phaseId = Number(rawData.roundNum) - 1;
  }
}

export default MatchKnockoutPhase;
