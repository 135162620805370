export enum TargetType {
  SINGLE = 1,
  AB = 2,
  ABC = 3,
  ABCD = 4,
  SRB = 5,
}

class Target {
  raw?: Record<string, any>;

  gameGroupId: number;

  qualifyingStartNum?: number | null;

  qualifyingEndNum?: number | null;

  qualifyingTargetType: number;

  knockoutStartNum?: number;

  knockoutEndNum?: number;

  singleRound?: number;

  constructor(rawData: Record<string, any>) {
    const {
      gameGroupId,
      qualifyingStartNum,
      qualifyingEndNum,
      qualifyingTargetType,
      knockoutStartNum,
      knockoutEndNum,
      singleRound,
    } = rawData;

    this.raw = rawData;
    this.gameGroupId = gameGroupId;
    this.qualifyingStartNum = qualifyingStartNum;
    this.qualifyingEndNum = qualifyingEndNum;
    this.qualifyingTargetType = qualifyingTargetType || TargetType.ABCD;
    this.knockoutStartNum = knockoutStartNum;
    this.knockoutEndNum = knockoutEndNum;
    this.singleRound = singleRound;
  }
}

export default Target;
