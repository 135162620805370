import _map from "lodash/map";

/** 团体混双 */
export class MatchTeam {
  rawData: Record<string, any>;

  teamId: string;

  name: string;

  matchId: string;

  groupId: string;

  members: Array<{
    userId: string;
    userName: string;
    sourceRegistrationId: string;
    sourceGroupId: string;
  }>;

  constructor(rawData: Record<string, any>) {
    this.rawData = rawData;
    this.teamId = String(rawData.mixedGroupId);
    this.name = rawData.name;
    this.matchId = String(rawData.gameId);
    this.groupId = String(rawData.gameGroupId);
    this.members = _map(rawData.mixedGroupUserList, (it) => ({
      userId: String(it.userId),
      userName: it.name,
      sourceRegistrationId: String(it.signId),
      sourceGroupId: String(it.sourceGameGroupId),
    }));
  }

  get raw() {
    return this.rawData;
  }
}

export type MatchTeamParams = {
  name?: string;
  groupId?: string;
  members: Array<{
    userId?: string;
    sourceRegistrationId?: string;
  }>;
}

export const mapTeamParamsToServerParams = (teamParams: MatchTeamParams): any => ({
  name: teamParams.name,
  gameGroupId: teamParams.groupId,
  mixedGroupUserList: _map(teamParams.members, (member) => ({
    userId: member.userId,
    signId: member.sourceRegistrationId,
  })),
});

export default MatchTeam;
