export enum GameGroupStatus {
  NOT_START = 0, // 未开始
  ROUND_ONE_START = 1, // 排位赛始
  ROUND_TWO_END = 4, // 排位赛结束
  KNOCKOUT_START = 5, // 淘汰赛开始
  KNOCKOUT_END = 6, // 淘汰赛结束
}

// 排位赛轮次状态
export enum RoundStatus {
  NOT_START = 0, // 未开始
  START = 1, // 进行中
  END = 2, // 已结束
}

export enum DataRange {
  ALL = 0, // 全部
  CLUB = 1, // 俱乐部
  OPEN = 2, // 公开
  OFFICIAL = 3, // 官方
}
