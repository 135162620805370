export class Phase {
  rawData: Record<string, any>;

  ends: Array<string>;

  finalEnds: Array<string>;

  roundId: string;

  phaseId: number;

  backendPhaseId: string;

  isExtra: boolean;

  constructor(rawData: Record<string, any>) {
    this.rawData = rawData;
    this.roundId = String(Number(rawData.roundNum) - 1);
    this.phaseId = Number(rawData.groupNum) - 1;
    this.ends = rawData.originRings?.split(",");
    this.finalEnds = rawData.finalRings?.split(",");
    this.backendPhaseId = String(rawData.id);
    this.isExtra = Boolean(rawData.extraFlag);
  }
}

export default Phase;
