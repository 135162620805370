import dayjs from "dayjs";

import { transcodeOssUrl } from "../oss";

export enum MatchDocumentType {
  UNSPECIFIED = 0,
  INDEX = 1,
}

export class MatchDocument {
  rawData: Record<string, any>;

  documentId: string;

  fileName: string;

  fileUrl: string;

  lastModifiedTimestamp: string;

  type: MatchDocumentType;

  get compatibleUrl(): string {
    const ossUrl = transcodeOssUrl(this.fileUrl) ?? "";
    return `https://www.archeryclub.cn/pdfjs/viewer.html?file=${encodeURIComponent(ossUrl)}`;
  }

  get humanReadableLastModifiedTimestamp(): string {
    return dayjs(this.lastModifiedTimestamp).format("YYYY-MM-DD HH:mm:ss");
  }

  matchId: string;

  constructor(rawData: Record<string, any>) {
    this.rawData = rawData;
    this.documentId = String(rawData.id);
    this.fileName = String(rawData.fileName);
    this.fileUrl = rawData.fileUrl;
    this.matchId = String(rawData.gameId);
    this.lastModifiedTimestamp = rawData.updateTime;
    this.type = rawData.showType as MatchDocumentType;
  }

  get raw(): Record<string, any> {
    return this.rawData;
  }
}

export default MatchDocument;
