<template>
  <a-layout>
    <a-layout-header v-if="!shouldHideHeader" class="app-header">
      <nav
        :class="{
          'zero-left-padding': shouldShowNavToggle
        }"
      >
        <div class="nav-space">
          <template v-if="shouldShowNavToggle">
            <div
              class="nav-toggle"
              @click="isNavVisibleOverride = !(isNavVisibleOverride ?? isTabletOrAbove)"
            >
              <a-button size="large">
                <template #icon>
                  <IconMenu />
                </template>
              </a-button>
            </div>
            <div class="nav-divider">
              <a-divider direction="vertical" />
            </div>
          </template>
          <router-link to="/" class="header-logo-container">
            <img :src="logoSrc" :style="{ width: '36px', height: '36px' }" />
            <a-typography-title v-if="isTabletOrAbove" :heading="6">
              一箭
            </a-typography-title>
            <a-typography-title v-else :heading="6">
              一箭
            </a-typography-title>
          </router-link>
          <a-space style="margin-left: 24px;">
            <a
              :href="`https://forum.archeryclub.cn/${otpQuery}`"
              class="nav-link"
            >
              论坛
            </a>
          </a-space>
        </div>
        <a-space>
          <template v-if="window.environment === 'electron'">
            <router-link to="/debug">
              <a-button>Internal Settings</a-button>
            </router-link>
          </template>
          <UserHeaderDropdown></UserHeaderDropdown>
        </a-space>
      </nav>
      <a-divider style="margin: 0" />
    </a-layout-header>
    <a-layout-content class="app-main">
      <ErrorBoundaryArco>
        <RouterView />
      </ErrorBoundaryArco>
    </a-layout-content>
    <a-layout-footer v-if="!shouldHideHeader && !shouldHideFooter" class="app-footer">
      <a-divider style="margin: 0" />
      <a-row class="footer-bottom">
        <a-space>
          <div class="footer-bottom-copyright">&copy; 2024 光势科技. All rights reserved.</div>
          <a-divider direction="vertical" />
          <div class="footer-bottom-copyright">{{ appVersion ?? "--" }}</div>
        </a-space>
        <div>
          <a href="https://beian.miit.gov.cn/">苏ICP备2022007236号-1</a>
        </div>
      </a-row>
    </a-layout-footer>
  </a-layout>
</template>

<script setup lang="ts">
import { IconMenu } from "@arco-design/web-vue/es/icon";
import { useDark, useWindowSize } from "@vueuse/core";
import {
  computed, watch, provide, ref,
} from "vue";
import { RouterLink, RouterView, useRoute } from "vue-router";

import { useUserStore } from "./store/user";

import logoSrc from "@/assets/images/logo.png";
import ErrorBoundaryArco from "@/components/ErrorBoundaryArco.vue";
import UserHeaderDropdown from "@/components/UserHeaderDropdown.vue";
import { useConfigStore } from "@/store/config";
import { updateServerDate } from "@/utils/time";

const appVersion = __APP_VERSION__;

const route = useRoute();

// Time sync

updateServerDate();

// OTP

const userStore = useUserStore();

const otpQuery = computed(() => (userStore.token != null ? `?otp=${userStore.token}` : ""));

// Navbar Toggle

const shouldShowNavToggle = computed(() => route.matched.some((it) => it.meta.showNavToggle));

const isNavVisibleOverride = ref<boolean | undefined>();

const { width } = useWindowSize();

const isTabletOrAbove = computed(() => width.value >= 768);

const isNavVisible = computed(() => isNavVisibleOverride.value ?? isTabletOrAbove.value);

provide("isNavVisible", isNavVisible);

watch(route, () => {
  if (!isTabletOrAbove.value) {
    isNavVisibleOverride.value = undefined;
  }
});

// Header and footer

const shouldHideHeader = computed(() => route?.meta?.fullScreen);

const shouldHideFooter = computed(() => route?.path !== "/");

// Dark mode

const configStore = useConfigStore();

const defaultIsDark = useDark();

watch(() => configStore.darkMode ?? defaultIsDark.value, (isEnabled) => {
  if (isEnabled) {
    document.body.setAttribute("arco-theme", "dark");
  } else {
    document.body.removeAttribute("arco-theme");
  }
}, { immediate: true });
</script>

<style lang="less">
@import "@/assets/base.less";

* {
  outline: none;
}

a {
  text-decoration: none;
  color: rgb(var(--arcoblue-6));
}

body {
  background-color: var(--color-bg-1);
}

.app-header {
  box-sizing: border-box;
  width: 100%;
  background-color: var(--color-neutral-1);

  height: @nav-height;
  z-index: 1000;

  position: fixed;
}

nav {
  display: flex;
  justify-content: space-between;

  padding: 0 12px;

  &.zero-left-padding {
    padding-left: 0;
  }
}

.header-logo-container {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  h6 {
    margin: 0 0 0 12px !important;
  }

  text-decoration: none;

  padding: 12px 0;
}

.app-main {
  min-height: calc(100vh);
  display: flex;
  flex-direction: column;

  padding-top: @nav-height;
}

.app-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 80px 20px 0;
  background-color: var(--color-neutral-1);
  box-sizing: border-box;

  .footer-bottom {
    flex: 1;
    padding: 20px 0;

    justify-content: space-between;
    align-items: center;

    &-copyright {
      font-size: 12px;
      line-height: 12px;
      color: var(--color-text-3);
    }
  }
}

.nav-space {
  display: flex;
  flex-direction: row;

  align-items: stretch;

  .nav-divider {
    display: flex;
    align-items: center;
    .arco-divider {
      margin-left: 0;
    }
  }

  .nav-toggle {
    width: @nav-height;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav-link {
    color: var(--color-neutral-8);
    &:hover {
      color: var(--color-neutral-10);
    }
  }
}
</style>
