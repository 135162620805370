export class Training {
  rawData: Record<string, any>;

  userId: number;

  name: string;

  clubName: string;

  totalDayCount: number;

  totalArrowCount: number;

  clubShortName: string;

  clubFullName: string;

  constructor(rawData: Record<string, any>) {
    this.rawData = rawData;
    this.userId = rawData.userId;
    this.name = rawData.nickname || rawData.realName;
    this.clubName = rawData.clubShortName || rawData.clubFullName;
    this.clubShortName = rawData.clubShortName;
    this.clubFullName = rawData.clubFullName;
    this.totalDayCount = rawData.totalDayCount;
    this.totalArrowCount = rawData.totalArrowCount;
  }
}
