import rule from "./rule.json";

const objectFlip = (obj): Record<any, any> => {
  const ret = {};
  Object.keys(obj).forEach((key) => {
    ret[ obj[ key ] ] = key;
  });
  return ret;
};
rule.columns = rule.columns.map((column) => ({
  ...column,
  reverseMapping: objectFlip(column.mapping),
  // @ts-ignore
  reversedBackendMapping: !column.backendMapping ? undefined : objectFlip(column.backendMapping),
}));

export { rule };

export const columnMap = Object.fromEntries(rule.columns.map((column, index) => [ column.name, {
  ...column,
  index,
} ]));

const generateMask = (data: Record<string, string>, exact = false): RegExp => {
  const mask = new Array(rule.columns.length).fill(exact ? "#" : ".");
  Object.entries(data).forEach(([ name, value ]) => {
    const column = columnMap[ name ];
    // @ts-ignore
    const encoded = column.reverseMapping[ value ];
    if (!encoded) throw Error();
    mask[ column.index ] = encoded;
  });
  return new RegExp(mask.join(""));
};

export const getAvailableColumnAndOptions = (
  existingData: Record<string, string>,
  filterOutDeterministicColumn = true,
): Array<{ name: string; options: Array<string>}> => {
  // Step 1: Generate mask
  const mask = generateMask(existingData);

  // Step 2: Filter options
  const encodedAvailableOptions = rule.encoded.filter((encodedOptions) => mask.test(encodedOptions));

  // Step 3: Split and transpose
  const loose = encodedAvailableOptions.map((option) => option.split(""));
  if (!loose.length) return [];
  const transpose = loose[ 0 ].map((_, colIndex) => loose.map((row) => row[ colIndex ]));

  // Step 4: Get unique options
  const uniqueValues = transpose.map((columnValues) => [ ...new Set(columnValues) ]);

  // Step 5: Return any column with more than one available options
  const result = uniqueValues.map((encodedOptions, index) => {
    if (encodedOptions.length <= 1 && filterOutDeterministicColumn) return null;
    const column = rule.columns[ index ];
    return {
      name: column.name,
      options: encodedOptions.map((encodedOption) => column.mapping[ encodedOption ]),
    };
  }).filter((v) => !!v);

  // @ts-ignore
  return result;
};

export const verify = (data: Record<string, string>, exact = false): boolean => {
  // Step 1: Generate mask
  const mask = generateMask(data, exact);

  // Step 2: Filter options
  return rule.encoded.some((encodedOptions) => mask.test(encodedOptions));
};

export const areEqual = (
  left: Record<string, string>,
  right: Record<string, string>,
): boolean => rule.columns.every((column) => left[ column.name ] === right[ column.name ]);

export const mapToBackendFormat = (data: Record<string, any>): Record<string, any> => {
  const entries = Object.entries(data).map(([ name, value ]): Array<any> | null | undefined => {
    const column = columnMap[ name ];
    if (!column) return;
    // @ts-ignore
    const backendMapping = column?.backendMapping;
    if (!backendMapping) return;
    // @ts-ignore
    // eslint-disable-next-line consistent-return
    return [ column?.backendKey, backendMapping[ value ] ];
  });
    // @ts-ignore
  const result = Object.fromEntries(entries.filter((v) => !!v));
  return result;
};

// @ts-ignore
const backendKeyColumnMap = Object.fromEntries(Object.values(columnMap).map((column) => [ column.backendKey, column ]));

export const mapToFrontendFormat = (data: Record<string, any>): Record<string, any> => {
  const entries = Object.entries(data).map(([ backendKey, backendValue ]): Array<any> | null | undefined => {
    const column = backendKeyColumnMap[ backendKey ];
    if (!column) return;
    const reversedBackendMapping = column?.reversedBackendMapping;
    if (!reversedBackendMapping) return;
    // eslint-disable-next-line consistent-return
    return [ column?.name, reversedBackendMapping[ backendValue ] ];
  });
  // @ts-ignore
  const result = Object.fromEntries(entries.filter((v) => !!v));
  return result;
};

export const bowTypes = columnMap[ "弓种" ].mapping;

export const distances = columnMap[ "距离" ].mapping;

export const targetFaces = columnMap[ "靶纸" ].mapping;

export const clientOnlyTargetFaces = [ "侯靶" ];
