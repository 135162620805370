import dayjs from "dayjs";

import { transcodeOssUrl } from "../oss";

export enum GameType {
  NOVICE = 1, // 新手打榜
  MEMBER = 2, // 会员打榜
  YOUNG = 3, // 青少年打榜
  STANDARD = 4 // 标准比赛
}

export enum GameStatus {
  NOT_START = 0, // 未开始
  IN_PROGRESS = 1, // 进行中
  ENDED = 2, // 已结束
}

export enum ScoreMode {
  DEFAULT = 0,
  SCORE_ON_SAME_TARGET = 1, // 单靶位记分
  SCORER_ONLY = 2, // 记分员模式
}

export class Game {
  rawData: Record<string, any>;

  name: string;

  matchId: string;

  // Deprecated
  gameId: number;

  clubId: number;

  province: string;

  city: string;

  address: string;

  status: number; // GameStatus

  startTime: string;

  endTime: string;

  multipleSign: boolean;

  registrationStartIsoTimestamp: string;

  registrationDeadlineIsoTimestamp: string;

  openToPublic: boolean;

  type: GameType;

  scoreMode: ScoreMode;

  needId: boolean;

  needLeader: boolean;

  needPhone: boolean;

  needTeam: boolean;

  officialFlag: boolean;

  clubLogo: string;

  clubShortName: string;

  clubFullName: string;

  mixedGroupFlag: boolean;

  knockoutLimit: number;

  playerLimit: number;

  qualifyingExtraFlag: boolean;

  host: string;

  execute: string;

  guidanceUnit: string;

  contact: string;

  webShowStartTime: string;

  webShowEndTime: string;

  constructor(rawData: Record<string, any>) {
    this.rawData = rawData;
    this.name = rawData.name;
    this.gameId = rawData.gameId;
    this.matchId = rawData.gameId;
    this.clubId = rawData.clubId;
    this.province = rawData.province;
    this.city = rawData.city;
    this.address = rawData.address;
    this.status = rawData.status;
    this.startTime = rawData.startTime;
    this.endTime = rawData.endTime;
    this.registrationStartIsoTimestamp = rawData.signStartTime;
    this.registrationDeadlineIsoTimestamp = rawData.signEndTime;
    this.openToPublic = Boolean(rawData.publicFlag);
    this.type = rawData.gameType as GameType;
    this.scoreMode = rawData.scoreMode as ScoreMode;
    this.needId = Boolean(rawData.needId);
    this.needLeader = Boolean(rawData.needLeader);
    this.needPhone = Boolean(rawData.needPhone);
    this.needTeam = Boolean(rawData.needTeam);
    this.officialFlag = Boolean(rawData.officialFlag);
    this.clubLogo = transcodeOssUrl(rawData.clubLogo) || "";
    this.clubShortName = rawData.clubShortName;
    this.clubFullName = rawData.clubFullName;
    this.multipleSign = Boolean(rawData.multipleSign);
    this.mixedGroupFlag = Boolean(rawData.mixedGroupFlag);
    this.knockoutLimit = rawData.knockoutLimit;
    this.playerLimit = rawData.playerLimit;
    this.qualifyingExtraFlag = Boolean(rawData.qualifyingExtraFlag);
    this.host = rawData.host;
    this.execute = rawData.execute;
    this.guidanceUnit = rawData.guidanceUnit;
    this.contact = rawData.contact;
    this.webShowStartTime = rawData.webShowStartTime;
    this.webShowEndTime = rawData.webShowEndTime;
  }

  get raw(): this["rawData"] {
    return this.rawData;
  }

  get hasStarted(): boolean {
    return dayjs(this.startTime).isBefore(dayjs());
  }

  get registrationNotStarted(): boolean {
    return dayjs(this.registrationStartIsoTimestamp).isAfter(dayjs());
  }

  get hasRegistrationClosed(): boolean {
    return dayjs(this.registrationDeadlineIsoTimestamp).isBefore(dayjs());
  }

  get humanReadableStartDate(): string {
    return this.startTime ? dayjs(this.startTime).format("MM[月]DD[日]") : "";
  }

  get humanReadableStartDateWithYear(): string {
    return this.startTime ? dayjs(this.startTime).format("YYYY年MM月DD日") : "";
  }

  get humanReadableEndDateWithYear(): string {
    return this.endTime ? dayjs(this.endTime).format("YYYY年MM月DD日") : "";
  }

  get humanReadableEndDate(): string {
    return this.endTime ? dayjs(this.endTime).format("MM[月]DD[日]") : "";
  }

  get showMathDate(): string {
    if (this.webShowEndTime && this.webShowStartTime) {
      return `${dayjs(this.webShowStartTime).format("MM月DD日")} - ${dayjs(this.webShowEndTime).format("MM月DD日")}`;
    }
    if (this.webShowStartTime) {
      return `${dayjs(this.webShowStartTime).format("MM月DD日")}`;
    }
    if (this.startTime && this.endTime) {
      return `${this.humanReadableStartDate} - ${this.humanReadableEndDate}`;
    }
    return this.humanReadableStartDate;
  }

  get showMathDateWithYear(): string {
    if (!this.webShowEndTime && this.webShowStartTime) {
      return `${dayjs(this.webShowStartTime).format("YYYY年MM月DD日")}`;
    }
    if (this.webShowEndTime && this.webShowStartTime) {
      return `${dayjs(this.webShowStartTime).format("YYYY年MM月DD日")} - ${dayjs(this.webShowEndTime).format("MM月DD日")}`;
    }
    if (!this.endTime) {
      return this.humanReadableStartDateWithYear;
    }
    return `${this.humanReadableStartDateWithYear} - ${this.humanReadableEndDateWithYear}`;
  }
}

export default Game;
