import { defineStore, acceptHMRUpdate } from "pinia";

import { axiosInstance } from "@/serviceInstance";
import { adminServices, userServices } from "@ac/services";
import User from "@ac/services/model/User";

import type { FileType } from "@ac/services/model/FileType";
import type { Token } from "@ac/services/user";

interface State {
  token: string | null;
  adminToken: string | null;
  userId: string | null;
  userRawData: User["rawData"] | null;
}

export const useUserStore = defineStore("user", {
  state: (): State => ({
    token: null,
    adminToken: null,
    userId: null,
    userRawData: null,
  }),
  getters: {
    jwtToken: (state: State): string | null => state.token,
    user: (state: State): User | null => (state.userRawData != null ? new User(state.userRawData) : null),
    role: (state: State): User["rawData"]["role"] | null => (state.userRawData != null ? state.userRawData.role : null),
  },
  actions: {
    async coldStartRefresh(): Promise<void> {
      // no op
    },
    setToken(payload: { token: string | null }): void {
      this.token = payload.token;
    },
    setAdminToken(payload: { adminToken: string | null }): void {
      this.adminToken = payload.adminToken;
    },
    async checkToken(): Promise<User | null> {
      const profile = await userServices.getProfile(axiosInstance, { userId: this.userId ?? undefined });
      if (profile == null) {
        return profile;
      }
      this.userId = profile.userId;
      this.userRawData = profile.rawData;
      return profile;
    },
    async checkAdminToken(): Promise<void> {
      await adminServices.getManagedClubs(axiosInstance);
    },
    async logout(): Promise<void> {
      this.token = null;
      this.adminToken = null;
      this.userId = null;
      this.userRawData = null;
    },
    async wechatWebLogin(
      payload: {
        code: string;
      },
    ): Promise<Token> {
      return userServices.wechatWeblogin(axiosInstance, payload);
    },
    async upload(
      payload: {
        fileType: FileType;
        file: File;
      },
      extra: any,
    ): Promise<string> {
      if (this.userId == null) throw new Error("UserId is empty");
      return userServices.upload(axiosInstance, {
        ...payload,
        userId: this.userId,
      }, extra);
    },
  },
  persist: true,
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
