import axios from "axios";

import logger from "../logger";

import { defaultEndpoint } from "./endpoint";

import type { AxiosAdapter } from "axios";

export type Token = string;

export type AxiosInstance = {
  get: any;
  post: any;
  getHeaderToken: () => string;
  baseUrl: string;
}

export const createInstance = ({
  adapter,
  loginEndpoint,
  getEndpoint,
  getStoredToken,
  dispatchLoginAction,
  getDefaultEndpoint,
  onInvalidToken,
}: {
  adapter?: AxiosAdapter;
  loginEndpoint: string;
  getEndpoint?: () => string | null;
  getStoredToken: () => Token | null;
  dispatchLoginAction: () => Promise<Token>;
  getDefaultEndpoint: () => string;
  onInvalidToken?: () => void;
}): AxiosInstance => {
  if (adapter) {
    axios.defaults.adapter = adapter;
  }

  let server = getDefaultEndpoint();
  try {
    server = getEndpoint?.() ?? server;
  } catch (e) {
    // Ignore error
  }
  if (server == null) throw new Error("Unable to connect mqtt. Server is null.");
  logger.info("Endpoint =", server);
  const baseUrl = `${server}/archery-club`;
  const instance = axios.create({
    baseURL: baseUrl,
    timeout: 60000,
    headers: {
      "Content-Type": "application/json",
    },
  });

  instance.interceptors.request.use((request) => {
    logger.info(`Request ${request.url}`, request);
    return request;
  }, (error) => {
    logger.error(`Request error:`, error);
    return Promise.reject(error);
  });

  instance.interceptors.response.use((response) => {
    logger.info(`Response ${response}`, response);
    return response;
  }, (error) => {
    logger.error(`Response error:`, error);
    return Promise.reject(error);
  });

  let isRefreshing = false;
  let requests: Array<(token: Token) => void> = [];

  instance.interceptors.response.use((response) => {
    if (![ "0", 200 ].includes(response.data.code)) {
      // TODO: set login code interceptor
      if (response.data.code === "-1"
      && response.data.msg.includes("jwt")
      && !response.config.url?.includes(loginEndpoint)) {
        const config = response.config;
        if (!isRefreshing) {
          logger.info("Token may be expired. Try to refresh...");
          isRefreshing = true;
          return dispatchLoginAction().then((token: Token) => {
            if (!token) throw new Error("Login failed");
            // @ts-ignore
            config.headers.Authorization = `Bearer ${token}`;
            requests.forEach((cb) => cb(token));
            requests = [];
            return instance(config);
          }).catch((err: Error) => {
            // TODO: unable to refresh token. backend is down
            Promise.reject(err);
          }).finally(() => {
            isRefreshing = false;
          });
        }
        return new Promise((resolve) => {
          requests.push((token) => {
          // @ts-ignore
            config.headers.Authorization = `Bearer ${token}`;
            resolve(instance(config));
          });
        });
      }
      if (response.data.code === "100004") {
        onInvalidToken?.();
      }

      const contentType = response.headers[ "Content-Type" ] ?? response.headers[ "content-type" ] ?? "";
      if ([
        "application/octet-stream",
        "application/vnd.ms-excel",
        "application/vnd.ms-excel;charset=utf-8",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        "application/pdf;charset=utf-8",
      ].includes(contentType)) {
        return response;
      }

      const error = new Error(response.data.msg);
      // @ts-ignore
      error.response = response;
      return Promise.reject(error);
    }
    return response;
  });
  // We don't need the error handler since http status code is not used.
  // (error) => {
  //   if (!error.response) {
  //     return Promise.reject(error);
  //   }
  //   // TODO: set login code interceptor
  //   if (error.response.code === "-1" && !error.config.url.includes(LOGIN_ENDPOINT)) {
  //     const { config } = error;
  //     if (!isRefreshing) {
  //       logger.info("Token may be expired. Try to refresh...");
  //       isRefreshing = true;
  //       return store.dispatch("user/login").then((token) => {
  //         config.headers.Authorization = `Bearer ${token}`;
  //         requests.forEach((cb) => cb(token));
  //         requests = [];
  //         return instance(config);
  //       }).catch((err) => {
  //         // TODO: unable to refresh token. backend is down
  //         Promise.reject(err);
  //       }).finally(() => {
  //         isRefreshing = false;
  //       });
  //     }
  //     return new Promise((resolve) => {
  //       requests.push((token) => {
  //         config.headers.Authorization = `Bearer ${token}`;
  //         resolve(instance(config));
  //       });
  //     });
  //   }
  //   return Promise.reject(error);
  // }

  const getHeaderToken = (): string => {
    const token = getStoredToken();
    return token ? `Bearer ${token}` : "";
  };

  const setHeaderToken = async (requireToken: boolean): Promise<void> => {
    instance.defaults.headers.common.Authorization = requireToken ? getHeaderToken() : "";
  };

  const get = async (url: string, params = {}, requireToken = false): Promise<any> => {
    setHeaderToken(requireToken);
    return instance({
      method: "get",
      url,
      params,
    });
  };

  const post = async (
    url: string,
    params: Record<string, any> = {},
    requireToken = false,
    extra = {},
  ): Promise<any> => {
    setHeaderToken(requireToken);
    if (params?.file != null) {
      const formData = new FormData();
      Object.keys(params).forEach((key) => {
        formData.append(key, params[ key ]);
      });
      return instance({
        method: "post",
        url,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        ...extra,
      });
    }
    return instance({
      method: "post",
      url,
      data: params,
      ...extra,
    });
  };

  return {
    get, post, getHeaderToken, baseUrl,
  };
};

export default createInstance;
