import { StaffType } from "./Staff";

export enum MatchAccessControlRuleType {
  USER_ID = 1,
  ROLE_GROUP = 2,
  ALL = 3,
}

export class MatchAccessControlRule {
  rawData: Record<string, any>;

  index: number;

  accessKeyOrArea: string;

  type: MatchAccessControlRuleType;

  staffTypes?: Array<StaffType>;

  userIds?: Array<string>;

  groupIds?: Array<string>;

  hasAccess: boolean;

  constructor(rawData: Record<string, any>) {
    this.rawData = rawData;
    this.index = Number(rawData.seq ?? "0");
    this.accessKeyOrArea = rawData.area;
    this.type = rawData.ruleType as MatchAccessControlRuleType;
    this.staffTypes = rawData.staffTypes?.split(",")?.map((it) => Number(it) as StaffType);
    this.userIds = rawData.userIds?.split(",");
    this.groupIds = rawData.gameGroupIds?.split(",");
    this.hasAccess = Boolean(rawData.accessFlag);
  }

  get serverParams(): any {
    return {
      seq: this.index,
      area: this.accessKeyOrArea,
      ruleType: this.type as number,
      userIds: this.userIds?.join(","),
      staffTypes: this.staffTypes?.join(","),
      gameGroupIds: this.groupIds?.join(","),
      accessFlag: Number(this.hasAccess),
    };
  }
}

export const RuleDefinition = [
  {
    type: MatchAccessControlRuleType.ALL,
    name: "全局",
    defaultValue: () => new MatchAccessControlRule({
      ruleType: MatchAccessControlRuleType.ALL,
    }),
  },
  {
    type: MatchAccessControlRuleType.USER_ID,
    name: "用户ID",
    defaultValue: () => new MatchAccessControlRule({
      ruleType: MatchAccessControlRuleType.USER_ID,
    }),
  },
  {
    type: MatchAccessControlRuleType.ROLE_GROUP,
    name: "身份+组别",
    defaultValue: () => new MatchAccessControlRule({
      ruleType: MatchAccessControlRuleType.ROLE_GROUP,
    }),
  },
];
