import { Notification, Message, Modal } from "@arco-design/web-vue";
import "@arco-design/web-vue/es/notification/style/css.js";
import "@arco-design/web-vue/es/message/style/css.js";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { createApp } from "vue";

import App from "./App.vue";

import router from "@/router";
import store from "@/store";
import "dayjs/locale/zh-cn";

import "../index.less";

const app = createApp(App);

app.config.globalProperties.window = window;

app.use(store);

app.use(router);

app.component(VueQrcode.name, VueQrcode);

// eslint-disable-next-line no-underscore-dangle
Notification._context = app._context;
Notification.install(app);

// eslint-disable-next-line no-underscore-dangle
Message._context = app._context;
Message.install(app);

// eslint-disable-next-line no-underscore-dangle
Modal._context = app._context;
Modal.install(app);

app.config.globalProperties.$append = (path: string, pathToAppend: string): string =>
  path + (path.endsWith("/") ? "" : "/") + pathToAppend;

dayjs.extend(relativeTime, {
  thresholds: [
    { l: "s", r: 1 },
    { l: "m", r: 1 },
    { l: "mm", r: 59, d: "minute" },
    { l: "h", r: 1 },
    { l: "hh", r: 23, d: "hour" },
    { l: "d", r: 1 },
    { l: "dd", r: 29, d: "day" },
    { l: "M", r: 1 },
    { l: "MM", r: 11, d: "month" },
    { l: "y", r: 1 },
    { l: "yy", d: "year" },
  ],
  rounding: Math.floor,
});
dayjs.extend(duration);

dayjs.locale("zh-cn");

app.mount("#app");
