import dayjs, { Dayjs } from "dayjs";
import _flatten from "lodash/flatten";
import _isEmpty from "lodash/isEmpty";

import * as ruleHelpers from "../ruleHelper";
import {
  getHumanReadableNthFinal,
  getNthFinalsFromNumberOfParticipant,
  getNumberOfParticipantsForKnockoutRound,
} from "../utils/knockout";
import { numberToChinese } from "../utils/localization";

import { GameGroupStatus, RoundStatus } from "./GameGroupStatus";
import Rule from "./Rule";

export enum MatchGroupType {
  SINGLE = 1,
  MIXED_DOUBLE = 2,
  GROUP = 3,
}

export interface MatchGroupSetting {
  MANY: number;
  GROUP: number;
  PLAYOFF: number;
  RING: number;
}

export enum KnockoutRule {
  STANDARD = 1,
  QUANNENG = 2, // 全能赛，排位赛前8名保护两轮排位赛，打1/48赛制
}

// 附加赛规则 每组几箭 MANY 几组 GROUP 附加赛分数 PLAYOFF 获胜分数 RING
export type RuleType = {
  [ key in MatchGroupType ]: MatchGroupSetting;
}
export const RULE: RuleType = {
  [ MatchGroupType.SINGLE ]: {
    MANY: 3, GROUP: 5, PLAYOFF: 5, RING: 5,
  },
  [ MatchGroupType.MIXED_DOUBLE ]: {
    MANY: 4, GROUP: 4, PLAYOFF: 4, RING: 4,
  },
  [ MatchGroupType.GROUP ]: {
    MANY: 6, GROUP: 4, PLAYOFF: 4, RING: 4,
  },
};

export const formatToRule = (rule): MatchGroupSetting => {
  const type = rule?.groupType || MatchGroupType.SINGLE;
  const {
    knockoutArrowGroup, knockoutGroupCount, extraScore, knockoutWinScore,
  } = rule;
  // 未设置规则时，使用默认规则
  if (!knockoutArrowGroup && !knockoutGroupCount && !extraScore && !knockoutWinScore) {
    return RULE[ type ];
  }
  return {
    MANY: knockoutArrowGroup,
    GROUP: knockoutGroupCount,
    PLAYOFF: extraScore,
    RING: knockoutWinScore,
  };
};

export const getHumanReadableMatchGroupType = (type: MatchGroupType): string => {
  switch (type) {
  case MatchGroupType.SINGLE: return "个人";
  case MatchGroupType.MIXED_DOUBLE: return "混双";
  case MatchGroupType.GROUP: return "团体";
  default: return "未知";
  }
};

export interface Round {
  round: number;
  roundFmt: string;
  status: RoundStatus;
  statusFmt: string;
}

export class MatchGroup {
  rawData: Record<string, any>;

  startTime: Dayjs | null;

  rule: Rule

  matchId: string;

  groupId: string;

  parentGroupId: string | null;

  status: number;

  currentQualifyingRound: number;

  get currentRoundId(): number {
    return this.currentQualifyingRound - 1;
  }

  qualifyingStatus: RoundStatus;

  knockoutFlag: number;

  gender: number | null;

  cachedRuleHumanReadableName: string;

  cachedNameWithoutGender: string;

  alias: string | null;

  type: MatchGroupType | null;

  signFee: number | null;

  signProductId: string | null;

  signCount: number;

  knockoutPlayerNum: number;

  knockoutNthFinals: Array<{
    name: string;
    value: string;
  }>;

  qualifyingRoundSetList?: Array<{
    distance: number;
    roundNo: number;
  }>;

  knockoutMode: KnockoutRule | null;

  constructor(rawData: Record<string, any>, matchId?: string) {
    this.rawData = rawData;
    this.rule = new Rule(rawData);
    const startTime = rawData?.startTime;
    this.startTime = !startTime ? null : dayjs(startTime);
    this.matchId = rawData.gameId ?? rawData.matchId ?? matchId;
    this.groupId = rawData.gameGroupId ?? rawData.groupId;
    this.parentGroupId = rawData.parentId ? String(rawData.parentId) : null;
    if (this.groupId != null) {
      this.groupId = String(this.groupId);
    }
    this.status = rawData.controlStatus;
    this.knockoutFlag = rawData.knockoutFlag;
    this.gender = rawData.sex;
    this.cachedRuleHumanReadableName = this.rule.humanReadableName;
    this.alias = rawData.customName;
    this.cachedNameWithoutGender = this.nameWithoutGender;
    this.type = rawData.groupType;
    this.currentQualifyingRound = rawData.currentQualifyingRound || 1;
    this.qualifyingStatus = rawData.qualifyingStatus || RoundStatus.NOT_START;
    this.signFee = rawData.signFee;
    this.signProductId = rawData.signProductId;
    this.qualifyingRoundSetList = rawData.qualifyingRoundSetList;
    this.knockoutMode = (rawData.knockoutMode || 1) as KnockoutRule;
    this.signCount = rawData.signCount || 0;
    this.knockoutPlayerNum = rawData.knockoutPlayerNum || 0;
    this.knockoutNthFinals = this.getKnockoutNthFinals(this.knockoutPlayerNum).map((it) => ({
      name: getHumanReadableNthFinal(it, true),
      value: it,
    }));
  }

  get isDailyEvent(): boolean {
    return this.rule.decoded[ "打榜模板" ] !== "-";
  }

  get metadata(): {
    numberOfRounds: number;
    numberOfPhases: number;
    numberOfEnds: number;
    rule: Rule;
    } {
    const numberOfRounds = this.rawData.roundCount;
    const numberOfPhases = this.rawData.groupCount;
    const numberOfEnds = this.rawData.arrowGroup;
    return {
      numberOfRounds: numberOfRounds < 0 ? 1 : numberOfRounds,
      numberOfPhases,
      numberOfEnds,
      rule: this.rule,
    };
  }

  get bowType(): string {
    return this.rule.decoded[ "弓种" ];
  }

  get dailyEventType(): string {
    return this.rule.decoded[ "打榜模板" ];
  }

  get humanReadableName(): string {
    const date = this.startTime?.format("MM月DD日");
    const { customName, name } = this.rawData;
    const groupName = customName || name || this.rule.humanReadableName;
    return `${date ?? ""} ${this.rule.decoded[ "打榜模板" ] ?? ""} ${groupName}`;
  }

  get date(): string | undefined {
    return this.startTime?.format("YYYY-MM-DD");
  }

  get numberOfRounds(): number | undefined {
    return this.rawData?.roundCount ?? this.rawData?.numberOfRounds;
  }

  get numberOfPhases(): number | undefined {
    return this.rawData?.groupCount ?? this.rawData?.numberOfPhases;
  }

  get numberOfEnds(): number | undefined {
    return this.rawData?.arrowGroup ?? this.rawData?.numberOfEnds;
  }

  get typedStatus(): GameGroupStatus | null {
    return this.status as GameGroupStatus;
  }

  get genderHumanReadable(): string {
    switch (this.gender) {
    case 0:
      return "男子组";
    case 1:
      return "女子组";
    default:
      return "混合组";
    }
  }

  get matchGroupTypeHumanReadable(): string {
    if (!this.type) return "个人";
    switch (this.type) {
    case MatchGroupType.SINGLE:
      return "个人";
    case MatchGroupType.MIXED_DOUBLE:
      return "混合团体";
    case MatchGroupType.GROUP:
      return "团体";
    default:
      return "个人";
    }
  }

  get ruleWithGenderHumanReadable(): string {
    return `${this.rule.humanReadableName} ${this.genderHumanReadable}`;
  }

  get name(): string {
    if (this.alias != null && !_isEmpty(this.alias)) {
      return this.alias;
    }
    return this.ruleWithGenderHumanReadable;
  }

  get nameWithoutGender(): string {
    if (this.alias != null && !_isEmpty(this.alias)) {
      return this.alias;
    }
    return this.rule.humanReadableName;
  }

  static getHighestTypedStatus(groups: Array<MatchGroup>): GameGroupStatus {
    return Math.max(...groups.map((group) => group.status)) as GameGroupStatus;
  }

  static getHightKnockoutFlag(groups: Array<MatchGroup>): number {
    return Math.max(...groups.map((group) => group.knockoutFlag));
  }

  get roundSteps(): Array<string> {
    return [
      `未开赛`,
      ..._flatten(Array.from({ length: this.metadata.numberOfRounds }, (_, i) => [
        `第${numberToChinese(i + 1)}轮`,
        `中场`,
      ])),
      `淘汰赛`,
      `已结束`,
    ];
  }

  get currentStep(): number {
    if (this.status === GameGroupStatus.NOT_START) return 1;
    const currentRound = this.currentQualifyingRound || 1;
    const status = this.qualifyingStatus || 0;
    let step = currentRound + status;
    // 第二轮往后
    if (currentRound > 1) {
      step = step + currentRound - 1;
    }
    // 淘汰赛阶段
    if (this.status && this.status >= GameGroupStatus.ROUND_TWO_END) {
      step += (this.status - GameGroupStatus.ROUND_TWO_END) + 1;
    }
    return step;
  }

  get roundList(): Array<Round> {
    const statusList: Array<Round> = [];
    Array.from({ length: (this.rawData.roundCount + 1) }).forEach((_, i) => {
      let statusFmt = "未开始";
      let roundFmt = `第${numberToChinese(i + 1)}轮`;
      let status = RoundStatus.NOT_START;

      // 排位赛阶段
      if (i === this.currentQualifyingRound - 1) {
        status = this.qualifyingStatus;
        switch (this.qualifyingStatus) {
        case RoundStatus.NOT_START:
          statusFmt = "未开始";
          break;
        case RoundStatus.START:
          statusFmt = "进行中";
          break;
        case RoundStatus.END:
          statusFmt = "已结束";
          break;
        default:
          statusFmt = "未开始";
        }
      }
      // 比赛已结束
      if (i < this.currentQualifyingRound - 1
        || (i === this.currentQualifyingRound - 1 && this.status >= GameGroupStatus.ROUND_TWO_END)) {
        status = RoundStatus.END;
        statusFmt = "已结束";
      }
      // 淘汰赛阶段
      if (i === this.rawData.roundCount) {
        roundFmt = "淘汰赛";
        if (this.status >= GameGroupStatus.ROUND_TWO_END) {
          switch (this.status) {
          case GameGroupStatus.KNOCKOUT_END:
            status = RoundStatus.END;
            statusFmt = "已结束";
            break;
          case GameGroupStatus.KNOCKOUT_START:
            status = RoundStatus.START;
            statusFmt = "进行中";
            break;
          default:
            status = RoundStatus.NOT_START;
            statusFmt = "未开始";
          }
        }
      }
      statusList.push({
        round: i + 1,
        roundFmt,
        status,
        statusFmt,
      });
    });
    return statusList;
  }

  getKnockoutNthFinals(numberOfApplicants: number): Array<string> {
    switch (this.knockoutMode) {
    case KnockoutRule.STANDARD:
      return getNthFinalsFromNumberOfParticipant(numberOfApplicants);
    case KnockoutRule.QUANNENG:
      if (numberOfApplicants > 56) {
        return [ "48", "24", ...getNthFinalsFromNumberOfParticipant(32) ];
      } if (numberOfApplicants > 32) {
        return [ "24", ...getNthFinalsFromNumberOfParticipant(32) ];
      }
      return getNthFinalsFromNumberOfParticipant(numberOfApplicants);
    default:
      return [];
    }
  }

  getNumberOfParticipantsForKnockoutRound(
    nthFinal: number,
    groupNumberOfParticipants: number,
  ): number {
    switch (this.knockoutMode) {
    case KnockoutRule.STANDARD:
      return getNumberOfParticipantsForKnockoutRound(nthFinal, groupNumberOfParticipants);
    case KnockoutRule.QUANNENG:
      if (nthFinal < 24) {
        return getNumberOfParticipantsForKnockoutRound(nthFinal, groupNumberOfParticipants);
      } if (nthFinal === 24) {
        if (groupNumberOfParticipants > 56) {
          return 48;
        }
        return groupNumberOfParticipants - 8;
      } if (nthFinal === 48) {
        return 96 - (96 - (groupNumberOfParticipants - 8)) * 2;
      }
      return 0; /* invalid */
    default:
      return 0;
    }
  }

  getRankingRoundName(roundId: number /* start from 0 */): string {
    const distance = this.qualifyingRoundSetList?.find((it) => Number(it.roundNo) === roundId + 1)?.distance;
    return `排位${roundId + 1}${distance != null ? ` - ${distance}米` : ""}`;
  }
}

export type MatchGroupParams = {
  alias: string | undefined;
  type: number | undefined;
  ruleBowType: string | undefined;
  ruleDistance: string | undefined;
  admissionRank: number | undefined;
  knockoutLimit: number | undefined;
  gameRule: number;
  ruleTargetFace: string | undefined;
  ruleNumberOfRounds: number | undefined;
  ruleNumberOfPhases: number | undefined;
  ruleNumberOfEnds: number | undefined;
  ruleMinimalNumberOfParticipantsInGroup: number | undefined;
  knockoutArrowGroup: number | undefined;
  knockoutGroupCount: number | undefined;
  extraScore: number | undefined;
  knockoutWinScore: number | undefined;
  signFee: number | undefined;
  knockoutMode: number | undefined; // 1-标准 2-全能
  [ key: string ]: any;
}

export const mapGroupParamsToServerParams = (groupParams: MatchGroupParams): any => ({
  customName: groupParams.alias,
  groupType: groupParams.type,
  ...ruleHelpers.mapToBackendFormat({
    弓种: groupParams.ruleBowType,
    距离: groupParams.ruleDistance,
    靶纸: groupParams.ruleTargetFace,
  }),
  admissionRank: groupParams.admissionRank,
  knockoutLimit: groupParams.knockoutLimit,
  gameRule: groupParams.gameRule,
  roundCount: groupParams.ruleNumberOfRounds,
  groupCount: groupParams.ruleNumberOfPhases,
  arrowGroup: groupParams.ruleNumberOfEnds,
  playerGroup: groupParams.ruleMinimalNumberOfParticipantsInGroup,
  knockoutArrowGroup: groupParams.knockoutArrowGroup,
  knockoutGroupCount: groupParams.knockoutGroupCount,
  extraScore: groupParams.extraScore,
  knockoutWinScore: groupParams.knockoutWinScore,
  signFee: groupParams.signFee,
  qualifyingRoundSetList: groupParams?.qualifyingRoundSetList?.filter((item) => item.distance),
  knockoutMode: groupParams.knockoutMode,
});

export default MatchGroup;
