import * as ruleHelpers from "../ruleHelper";

export class Rule {
  raw: Record<string, any>;

  name: string;

  groupId: string;

  matchId: string;

  gameGroupIds: number[];

  admissionRank: number;

  knockoutLimit: number;

  gameRule: number;

  decoded: Record<string, any>;

  constructor(rawData: Record<string, any>) {
    this.raw = rawData;
    this.decoded = {
      模式: "每日打榜",
      ...ruleHelpers.mapToFrontendFormat(rawData),
    };

    this.name = rawData.name ?? `${this.decoded[ "弓种" ]}`;
    this.groupId = String(rawData.gameGroupId ?? rawData.ruleId);
    this.matchId = String(rawData.gameId);
    this.gameGroupIds = rawData.gameGroupIds;
    this.admissionRank = rawData.admissionRank;
    this.knockoutLimit = rawData.knockoutLimit;
    this.gameRule = rawData.gameRule;
  }

  get dailyEventMode(): string {
    return this.decoded[ "打榜模板" ];
  }

  get humanReadableName(): string {
    return `${this.decoded[ "弓种" ]} ${this.decoded[ "距离" ]} ${this.decoded[ "靶纸" ]}`;
  }

  // 淘汰赛对阵是否采用环胜
  get matchKnockoutMatchUpCountTotalRings(): boolean {
    return this.decoded[ "弓种" ] === "复合弓";
  }

  get bowType(): string {
    return this.decoded[ "弓种" ];
  }

  get distance(): string {
    return this.decoded[ "距离" ];
  }

  get targetFace(): string {
    return this.decoded[ "靶纸" ];
  }

  get numberOfRounds(): number {
    return this.raw.roundCount;
  }

  get numberOfPhases(): number {
    return this.raw.groupCount;
  }

  get numberOfEnds(): number {
    return this.raw.arrowGroup;
  }

  get minimalNumberOfParticipantsInGroup(): number {
    return this.raw.playerGroup;
  }

  get knockoutArrowGroup(): number {
    return this.raw.knockoutArrowGroup;
  }

  get knockoutGroupCount(): number {
    return this.raw.knockoutGroupCount;
  }

  get extraScore(): number {
    return this.raw.extraScore;
  }

  get knockoutWinScore(): number {
    return this.raw.knockoutWinScore;
  }
}

export default Rule;
