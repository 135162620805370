import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";

export enum EventType {
  PAGE_VIEW,
  CLICK,
}

export type Event = {
  userId?: string;
  deviceId?: string;
  metadata?: any;
} & (
  {
    event: EventType.PAGE_VIEW;
    path: string;
  } | {
    event: EventType.CLICK;
    path: string;
  }
);

export const logEvent = async (
  instance: any,
  payload: {event: Event},
): Promise<void> => {
  const encoded = {
    ...payload.event,
    event: EventType[ payload.event.event ],
    metadata: _isEmpty(_filter(payload.event.metadata, (it) => !!it))
      ? undefined : JSON.stringify(payload.event.metadata),
  };
  console.info("\u{1FAB5} Log event", encoded);
  try {
    await instance.post("/bus/userAccessLog/saveUserAccessLog", encoded, true);
  } catch (e) {
    console.error("Telemetry error", e);
  }
};
