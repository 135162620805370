import dayjs, { Dayjs } from "dayjs";

import { transcodeOssUrl } from "../oss";

export const DEFAULT_AVATAR = "https://archery-club-prod.oss-cn-zhangjiakou.aliyuncs.com/random-headimg/42658047.png";

export type UserId = string;

export enum Sex {
  MAN = 0,
  WOMAN = 1,
}

export enum Role {
  ADMIN = 1,
  CONTRIBUTOR = 2,
  CLUB = 3,
  MEMBER = 4,
}

export class User {
  rawData: Record<string, any>;

  userId: UserId;

  openId: string | null;

  unionId: string | null;

  nickName: string | null;

  realName: string | null;

  sex: number | null;

  idNo: string | null;

  phone: string | null;

  avatarUrl?: string;

  createTime: Dayjs | null;

  joinTime: Dayjs | null;

  clubId: string | null;

  clubFullName: string | null;

  clubShortName: string | null;

  subscriptionExpirationDateIso: string | null;

  hasClaimedFreeTrial: string | null;

  fullPosition?: string;

  role: Role;

  mixedGroupUserList: Array<{
    avatarUrl: string;
    name: string;
  }>;

  toolkitScoringStatistics?: {
    totalNumberOfEnds: number;
    numberOfDaysRecorded: number;
  }

  constructor(rawData: Record<string, any>) {
    this.rawData = rawData;

    const { targetLocation, targetNo } = rawData;

    this.userId = String(rawData.userId);
    this.openId = rawData.openId;
    this.unionId = rawData.unionId;
    this.nickName = rawData.nickName;
    this.realName = rawData.realName ?? rawData.name;
    this.sex = rawData.sex;
    this.phone = rawData.phone;
    this.idNo = rawData.idNo;
    this.avatarUrl = transcodeOssUrl(rawData.avatorOssUrl) || DEFAULT_AVATAR;
    this.createTime = dayjs(rawData.createTime);
    this.joinTime = dayjs(rawData.joinTime);
    this.clubId = rawData.clubId;
    this.clubFullName = rawData.clubFullName;
    this.clubShortName = rawData.clubShortName;
    this.subscriptionExpirationDateIso = rawData.expTime;
    this.hasClaimedFreeTrial = rawData.experienceFlag;
    this.role = rawData.role;
    this.toolkitScoringStatistics = {
      totalNumberOfEnds: rawData.totalArrowCount,
      numberOfDaysRecorded: rawData.totalDayCount,
    };
    this.fullPosition = `${targetNo || ""}${targetLocation || ""}`;
    this.mixedGroupUserList = rawData.mixedGroupUserList?.map((element) => ({
      ...element,
      avatarUrl: element.avatorOssUrl || DEFAULT_AVATAR,
    })) || [];
  }

  get hasValidSubscription(): boolean {
    return this.subscriptionExpirationDateIso != null && !dayjs().isAfter(this.subscriptionExpirationDateIso);
  }

  get subscriptionExpirationDate(): string {
    return dayjs(this.subscriptionExpirationDateIso).format("YYYY-MM-DD");
  }

  static decodeQrData(code: string): {
    userId: string;
  } | undefined {
    try {
      const data = JSON.parse(code);
      if (data.userId == null) {
        return undefined;
      }
      return data;
    } catch (e) {
      return undefined;
    }
  }
}

export default User;
