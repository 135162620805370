export const numberToChinese = (input: number): string => {
  let num = input;
  const chnNumChar = [ "零", "一", "二", "三", "四", "五", "六", "七", "八", "九" ];
  const chnUnitChar = [ "", "十", "百", "千" ];
  let strIns = "";
  let chnStr = "";
  let unitPos = 0;
  let zero = true;
  while (num > 0) {
    const v = num % 10;
    if (v === 0) {
      if (!zero) {
        zero = true;
        chnStr = chnNumChar[ v ] + chnStr;
      }
    } else {
      zero = false;
      strIns = chnNumChar[ v ];
      strIns += chnUnitChar[ unitPos ];
      chnStr = strIns + chnStr;
      if (chnStr[ 0 ] === "一" && chnStr[ 1 ] === "十") {
        chnStr = chnStr.replace("一十", "十");
      }
    }
    unitPos += 1;
    num = Math.floor(num / 10);
  }
  return chnStr;
};
