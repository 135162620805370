import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/esm/plugin/timezone";
import utc from "dayjs/esm/plugin/utc";

import logger from "./logger";

import { CST } from "@ac/services/utils/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

let diff = 0;

export const updateServerDate = (): void => {
  const xhr = new window.XMLHttpRequest();
  xhr.open("GET", "/", true);
  xhr.send(null);
  xhr.onreadystatechange = (): void => {
    if (xhr.readyState === 2) {
      const time = xhr.getResponseHeader("Date");
      diff = dayjs(time).diff(dayjs());
      logger.info("Time diff", diff);
    }
  };
};

export const st = (object: Dayjs): Dayjs => object.add(diff, "ms");

export const current = (): Dayjs => st(dayjs()).tz(CST);
