/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Message } from "@arco-design/web-vue";
import {
  IconCalendarClock,
  IconCheckSquare,
  IconCommon,
  IconDriveFile,
  IconEdit,
  IconExport,
  IconFullscreen,
  IconGift,
  IconHome,
  IconIdcard,
  IconPublic,
  IconRecord,
  IconRelation,
  IconSafe,
  IconSelectAll,
  IconStamp,
  IconSubscribe,
  IconTrophy,
  IconUser,
  IconUserAdd,
  IconUserGroup,
} from "@arco-design/web-vue/es/icon";
import { markRaw } from "vue";
import { createRouter, createWebHistory } from "vue-router";

import { logPageView } from "@/hooks/useTelemetry";
import PassThrough from "@/pages/PassThrough.vue";
import { useUserStore } from "@/store/user";
import { checkAuth } from "@/utils/auth";
import { Role } from "@ac/services/model/User";

import type { RouteRecordRaw } from "vue-router";

export const pages = {
  PAGE_ADMIN_CLUB_LEADER_REGISTRIES: "PAGE_ADMIN_CLUB_LEADER_REGISTRIES",
  PAGE_ADMIN_CLUB_MATCH_ACCESS_CONTROL: "PAGE_ADMIN_CLUB_MATCH_ACCESS_CONTROL",
  PAGE_ADMIN_CLUB_MATCH_DOCUMENTS: "PAGE_ADMIN_CLUB_MATCH_DOCUMENTS",
  PAGE_ADMIN_CLUB_MATCH_EXPORT: "PAGE_ADMIN_CLUB_MATCH_EXPORT",
  PAGE_ADMIN_CLUB_MATCH_REGISTRIES: "PAGE_ADMIN_CLUB_MATCH_REGISTRIES",
  PAGE_ADMIN_CLUB_MATCH_SCHEDULE: "PAGE_ADMIN_CLUB_MATCH_SCHEDULE",
  PAGE_ADMIN_CLUB_MATCH_SCORERS: "PAGE_ADMIN_CLUB_MATCH_SCORERS",
  PAGE_ADMIN_CLUB_MATCH_SCORES: "PAGE_ADMIN_CLUB_MATCH_SCORES",
  PAGE_ADMIN_CLUB_MATCH_STAFF_REGISTRIES: "PAGE_ADMIN_CLUB_MATCH_STAFF_REGISTRIES",
  PAGE_ADMIN_CLUB_MATCH_TARGETS: "PAGE_ADMIN_CLUB_MATCH_TARGETS",
  PAGE_ADMIN_CLUB_MATCH_TEAMS: "PAGE_ADMIN_CLUB_MATCH_TEAMS",
  PAGE_ADMIN_CLUB_MATCH: "PAGE_ADMIN_CLUB_MATCH",
  PAGE_ADMIN_CLUB_MATCHES: "PAGE_ADMIN_CLUB_MATCHES",
  PAGE_ADMIN_CLUB_TEAMS: "PAGE_ADMIN_CLUB_TEAMS",
  PAGE_ADMIN_CLUB: "PAGE_ADMIN_CLUB",
  PAGE_ADMIN_CLUBS: "PAGE_ADMIN_CLUBS",
  PAGE_ADMIN_INDEX_MATCH_DOCUMENTS: "PAGE_ADMIN_INDEX_MATCH_DOCUMENTS",
  PAGE_ADMIN_LOGIN: "PAGE_ADMIN_LOGIN",
  PAGE_ADMIN: "PAGE_ADMIN",
  PAGE_TRAINING_SCORING_SESSION: "PAGE_TRAINING_SCORING_SESSION",
  PAGE_BIG_SCREEN: "PAGE_BIG_SCREEN",
  PAGE_BIG_SCREEN_FULL: "PAGE_BIG_SCREEN_FULL",
};

export const menuRoutes: Array<RouteRecordRaw> = [
  {
    path: "dashboard",
    component: () => import("@/pages/admin/Dashboard.vue"),
    meta: {
      icon: markRaw(IconHome),
      hideInBreadcrumb: true,
      displayName: "控制台",
    },
  },
  {
    path: "clubs",
    name: pages.PAGE_ADMIN_CLUBS,
    component: () => import("@/pages/admin/Clubs.vue"),
    meta: {
      icon: markRaw(IconPublic),
      displayName: "俱乐部管理",
    },
  },
  {
    path: "club/:clubId",
    name: pages.PAGE_ADMIN_CLUB,
    component: PassThrough,
    meta: {
      icon: markRaw(IconUserGroup),
      needClubId: true,
      displayName: ":clubName",
    },
    children: [
      {
        path: "",
        component: () => import("@/pages/admin/Club.vue"),
        meta: {
          icon: markRaw(IconIdcard),
          displayName: "俱乐部详情",
          hideInBreadcrumb: true,
        },
      },
      {
        path: "teams",
        name: pages.PAGE_ADMIN_CLUB_TEAMS,
        component: () => import("@/pages/admin/Teams.vue"),
        meta: {
          icon: markRaw(IconUserGroup),
          displayName: "代表队管理",
        },
      },
      {
        path: "matches",
        name: pages.PAGE_ADMIN_CLUB_MATCHES,
        component: () => import("@/pages/admin/Matches.vue"),
        meta: {
          icon: markRaw(IconTrophy),
          displayName: "比赛管理",
        },
      },
      {
        path: "match/:matchId",
        name: pages.PAGE_ADMIN_CLUB_MATCH,
        component: PassThrough,
        meta: {
          icon: markRaw(IconTrophy),
          displayName: ":matchName",
          needMatchId: true,
        },
        children: [
          {
            path: "",
            component: () => import("@/pages/admin/Match.vue"),
            meta: {
              icon: markRaw(IconSubscribe),
              displayName: "比赛详情",
              hideInBreadcrumb: true,
            },
          },
          {
            path: "documents",
            name: pages.PAGE_ADMIN_CLUB_MATCH_DOCUMENTS,
            component: () => import("@/pages/admin/MatchDocuments.vue"),
            meta: {
              icon: markRaw(IconDriveFile),
              displayName: "比赛文件",
            },
          },
          {
            path: "registries",
            name: pages.PAGE_ADMIN_CLUB_MATCH_REGISTRIES,
            component: () => import("@/pages/admin/MatchRegistries.vue"),
            meta: {
              icon: markRaw(IconUserAdd),
              displayName: "报名管理",
            },
          },
          {
            path: "leaderRegistries",
            name: pages.PAGE_ADMIN_CLUB_LEADER_REGISTRIES,
            component: () => import("@/pages/admin/LeaderRegistries.vue"),
            meta: {
              icon: markRaw(IconRelation),
              displayName: "领队登记管理",
            },
          },
          {
            path: "staffRegistries",
            name: pages.PAGE_ADMIN_CLUB_MATCH_STAFF_REGISTRIES,
            component: () => import("@/pages/admin/MatchStaffRegistries.vue"),
            meta: {
              icon: markRaw(IconUser),
              displayName: "非参赛人员管理",
            },
          },
          {
            path: "teams",
            name: pages.PAGE_ADMIN_CLUB_MATCH_TEAMS,
            component: () => import("@/pages/admin/MatchTeams.vue"),
            meta: {
              icon: markRaw(IconRelation),
              displayName: "团体管理",
              roles: [ Role.ADMIN, Role.CONTRIBUTOR, Role.MEMBER ],
            },
          },
          {
            path: "schedule",
            name: pages.PAGE_ADMIN_CLUB_MATCH_SCHEDULE,
            component: () => import("@/pages/admin/MatchSchedule.vue"),
            meta: {
              icon: markRaw(IconCalendarClock),
              displayName: "场地日程",
              roles: [ Role.ADMIN, Role.CONTRIBUTOR ],
            },
          },
          {
            path: "accessControl",
            name: pages.PAGE_ADMIN_CLUB_MATCH_ACCESS_CONTROL,
            component: () => import("@/pages/admin/MatchAccessControl.vue"),
            meta: {
              icon: markRaw(IconSafe),
              displayName: "访问控制",
              roles: [ Role.ADMIN, Role.CONTRIBUTOR ],
            },
          },
          {
            path: "scorers",
            name: pages.PAGE_ADMIN_CLUB_MATCH_SCORERS,
            component: () => import("@/pages/admin/MatchScorers.vue"),
            meta: {
              icon: markRaw(IconEdit),
              displayName: "记分员",
              roles: [ Role.ADMIN, Role.CONTRIBUTOR, Role.MEMBER ],
            },
          },
          {
            path: "scores",
            name: pages.PAGE_ADMIN_CLUB_MATCH_SCORES,
            component: () => import("@/pages/admin/MatchScores.vue"),
            meta: {
              icon: markRaw(IconStamp),
              displayName: "成绩管理",
              roles: [ Role.ADMIN, Role.CONTRIBUTOR, Role.MEMBER ],
            },
          },
          {
            path: "targets",
            name: pages.PAGE_ADMIN_CLUB_MATCH_TARGETS,
            component: () => import("@/pages/admin/MatchTargets.vue"),
            meta: {
              icon: markRaw(IconRecord),
              displayName: "靶位管理",
              roles: [ Role.ADMIN, Role.CONTRIBUTOR, Role.MEMBER ],
            },
          },
          {
            path: "export",
            name: pages.PAGE_ADMIN_CLUB_MATCH_EXPORT,
            component: () => import("@/pages/admin/MatchExport.vue"),
            meta: {
              icon: markRaw(IconExport),
              displayName: "导出管理",
              roles: [ Role.ADMIN ],
            },
          },
          {
            path: "bigScreen",
            name: pages.PAGE_BIG_SCREEN,
            component: () => import("@/pages/admin/BigScreen.vue"),
            meta: {
              icon: markRaw(IconFullscreen),
              displayName: "大屏",
              roles: [ Role.ADMIN ],
            },
          },
        ],
      },
    ],
  },
  {
    path: "data",
    meta: {
      icon: markRaw(IconCommon),
      displayName: "数据管理",
      roles: [ Role.ADMIN ],
    },
    component: PassThrough,
    children: [
      {
        path: "trainingRecords",
        meta: {
          icon: markRaw(IconCheckSquare),
          displayName: "训练记录",
        },
        component: () => import("@/pages/admin/TrainingRecords.vue"),
      },
      {
        path: "allMatches",
        meta: {
          icon: markRaw(IconTrophy),
          displayName: "全部比赛",
        },
        component: () => import("@/pages/admin/AllMatches.vue"),
      },
      {
        path: "documents",
        name: pages.PAGE_ADMIN_INDEX_MATCH_DOCUMENTS,
        component: () => import("@/pages/admin/IndexMatchDocuments.vue"),
        meta: {
          icon: markRaw(IconDriveFile),
          displayName: "首页文件",
        },
      },
    ],
  },
  {
    path: "commodity",
    meta: {
      icon: markRaw(IconGift),
      displayName: "商品管理",
      roles: [ Role.ADMIN ],
    },
    component: PassThrough,
    children: [
      {
        path: "orderList",
        meta: {
          icon: markRaw(IconSelectAll),
          displayName: "订单管理",
        },
        component: () => import("@/pages/admin/OrderManage.vue"),
      },
      {
        path: "refundList",
        meta: {
          icon: markRaw(IconStamp),
          displayName: "退款管理",
        },
        component: () => import("@/pages/admin/RefundManage.vue"),
      },
    ],
  },
];

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    redirect: () => ({ path: "/admin/dashboard" }),
  },
  {
    path: "/user/login/:action*",
    component: () => import("@/pages/user/LoginPage.vue"),
  },
  {
    path: "/admin",
    name: pages.PAGE_ADMIN,
    component: () => import("@/pages/admin/index.vue"),
    meta: {
      icon: markRaw(IconHome),
      displayName: "控制台",
      showNavToggle: true,
    },
    children: [
      {
        path: "login",
        name: pages.PAGE_ADMIN_LOGIN,
        redirect: () => ({ path: "/user/login" }),
        meta: {
          displayName: "登录",
        },
      },
      ...menuRoutes,
    ],
  },
  {
    path: "/training/scoring/session/:sessionId",
    name: pages.PAGE_TRAINING_SCORING_SESSION,
    component: () => import("@/pages/training/ScoringSession.vue"),
  },
  {
    path: "/video-wall/club/:clubId",
    component: () => import("@/pages/VideoWallPage.vue"),
    meta: { fullScreen: true },
  },
  {
    name: pages.PAGE_BIG_SCREEN_FULL,
    path: "/big-screen/:schedule/:matchId",
    component: () => import("@/pages/BigScreenPage.vue"),
    meta: { fullScreen: true },
  },
  {
    path: "/debug",
    name: "debug",
    component: () => import("@/pages/DebugPage.vue"),
  },
  {
    path: "/data-view",
    name: "dataView",
    meta: { fullScreen: true },
    component: () => import("@/pages/Data.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("@/pages/NotFound.vue"),
  },
];
const router = createRouter({
  history: createWebHistory("/app/" /* import.meta.env.BASE_URL */),
  routes,
  strict: true,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});

router.beforeEach(async (to, from) => {
  // @ts-ignore
  window.NProgress.start();

  const userStore = useUserStore();
  if (to.path.startsWith("/admin") && to.path !== "/admin/login" && !userStore.token) {
    return {
      path: "/admin/login",
    };
  }
  // check auth
  if (!checkAuth(to.meta.roles ?? [])) {
    Message.error("您没有权限访问该页面");
    return from;
  }
  if (to.path === "/admin") {
    return {
      path: "/admin/dashboard",
    };
  }
  return undefined;
});

router.afterEach(() => {
  // @ts-ignore
  window.NProgress.done();

  logPageView();
});

export default router;
