import { Message } from "@arco-design/web-vue";
import _isEmpty from "lodash/isEmpty";

import router from "@/router";
import { useUserStore } from "@/store/user";
import { createAxiosInstance, createMqttInstance, createSubscriptionManager } from "@ac/services";
import { CUSTOMER_LOGIN_ENDPOINT, defaultEndpoint } from "@ac/services/axios/endpoint";
import { DEFAULT_ENDPOINT_WSS } from "@ac/services/mqtt/endpoint";

export const axiosInstance = createAxiosInstance({
  loginEndpoint: CUSTOMER_LOGIN_ENDPOINT,
  getDefaultEndpoint: () => {
    const isDev = import.meta.env.DEV;
    return isDev ? "/proxy-dev" : defaultEndpoint;
  },
  getEndpoint: () => {
    const configuredEndpoint = localStorage.getItem("config") as string;
    const endpointOverride = JSON.parse(configuredEndpoint).endpoint;
    return _isEmpty(endpointOverride) ? null : endpointOverride;
  },
  getStoredToken: () => useUserStore().jwtToken,
  dispatchLoginAction: () => {
    throw new Error("unimplemented");
  },
  onInvalidToken: async () => {
    Message.error("令牌无效或已过期，请重新登陆。");
    await useUserStore().logout();
    router.push("/user/login");
  },
});

export const mqttInstance = createMqttInstance({
  getDefaultEndpoint: () => DEFAULT_ENDPOINT_WSS,
  getEndpoint: () => {
    const configuredEndpoint = localStorage.getItem("config") as string;
    const endpointOverride = JSON.parse(configuredEndpoint).mqttEndpoint;
    return _isEmpty(endpointOverride) ? null : endpointOverride;
  },
});

export const subscriptionManager = createSubscriptionManager({ mqttInstance });

export default axiosInstance;
