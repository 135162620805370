<template>
  <div class="header-user-container">
    <template v-if="user">
      <a-button>
        <a-dropdown
          position="br"
          @select="handleSelect"
        >
          <div>
            <a-avatar
              :size="24"
              shape="square"
            >
              <img
                alt="avatar"
                :src="user?.avatarUrl"
              />
            </a-avatar>
            <span class="username">{{ user?.nickName ?? user?.realName ?? "用户" }}</span>
          </div>
          <template #content>
            <a-doption value="dashboard">控制台</a-doption>
            <a-doption value="logout">退出</a-doption>
          </template>
        </a-dropdown>
      </a-button>
    </template>
    <template v-else>
      <router-link to="/user/login" class="header-logo-container">
        <a-button>
          登录
        </a-button>
      </router-link>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { RouterLink, useRouter } from "vue-router";

import { useUserStore } from "@/store/user";
import { updateServerDate } from "@/utils/time";

updateServerDate();

const router = useRouter();

const userStore = useUserStore();

const user = computed(() => userStore.user);

const handleSelect = (value: any) => {
  switch (value) {
  case "dashboard": {
    router.push({ path: "/admin/dashboard" });
    break;
  }
  case "logout": {
    userStore.logout();
    router.push("/");
    break;
  }
  default: break;
  }
};
</script>

<style lang="less">
@import "@/assets/base.less";

.header-user-container {
  position: relative;
  .username {
    margin-left: 12px;
  }
}

</style>
