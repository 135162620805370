import { getHumanReadableNthFinal, preMapNthFinalAndSeqToNthFinal } from "../utils/knockout";

import KnockoutScore from "./KnockoutScore";

export enum STAGE {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  FINISHED = "FINISHED",
}

export class MatchKnockoutMatchUp2 {
  rawData: Record<string, any>;

  knockoutId: string;

  nthFinal: number;

  seq: number;

  scores: Array<KnockoutScore>;

  scoresWithSeqPlaceholder: Array<KnockoutScore | undefined>;

  hasResultBeenModified: boolean;

  constructor(rawData: Record<string, any>, matchKnockoutMatchUpCountTotalRings: boolean) {
    this.rawData = rawData;

    this.knockoutId = String(this.rawData.knockoutId);
    this.nthFinal = this.rawData.finalRound;
    this.seq = this.rawData.seq;

    this.scores = this.rawData.knockoutSummaryList
      ?.sort((a, b) => a.seq - b.seq)
      ?.map((it) => new KnockoutScore(it, matchKnockoutMatchUpCountTotalRings)) ?? [];
    this.scoresWithSeqPlaceholder = [ 1, 2 ]
      .map((i) => this.scores.find((score) => score.seq === i));
    this.hasResultBeenModified = Boolean(rawData.changeWin);
  }

  get knockoutScores(): Array<KnockoutScore> {
    return this.scores;
  }

  get roundId(): string {
    return `KNOCKOUT-${this.knockoutId}`;
  }

  get nthFinalHumanReadable(): string {
    return getHumanReadableNthFinal(preMapNthFinalAndSeqToNthFinal(this.nthFinal, this.seq), true);
  }

  get stage(): STAGE {
    const scoresWithSeqPlaceholder = this.scoresWithSeqPlaceholder || [];
    let stage: STAGE = STAGE.IN_PROGRESS;
    for (let index = 0; index < scoresWithSeqPlaceholder.length; index += 1) {
      const element = scoresWithSeqPlaceholder[ index ];
      if (!element) {
        stage = STAGE.NOT_STARTED;
        break;
      }
      const { winFlag, user } = element;
      if (user && [ null, undefined ].includes(winFlag)) {
        stage = STAGE.IN_PROGRESS;
        break;
      }
      stage = STAGE.FINISHED;
    }
    return stage;
  }

  get shouldSkipRendering(): boolean {
    return this.scoresWithSeqPlaceholder.every((it) => it && it.user?.userId == null);
  }
}

export default MatchKnockoutMatchUp2;
