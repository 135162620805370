export class Team {
  rawData: Record<string, any>;

  id: number;

  teamId: string;

  name: string;

  logo: number | null;

  constructor(rawData: Record<string, any>) {
    const { id, name, logo } = rawData;

    this.rawData = rawData;
    this.id = id;
    this.teamId = String(id);
    this.name = name;
    this.logo = logo;
  }

  get raw() {
    return this.rawData;
  }
}

export default Team;
