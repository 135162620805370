import { defineStore, acceptHMRUpdate } from "pinia";

interface State {
  endpoint?: string;
  mqttEndpoint?: string;
  darkMode?: boolean;
}

export const useConfigStore = defineStore("config", {
  state: (): State => ({
    endpoint: undefined,
    mqttEndpoint: undefined,
    darkMode: undefined,
  }),
  getters: {
    getDarkModeEnabled: (state: State): boolean | undefined => state.darkMode,
  },
  actions: {
    async coldStartRefresh(): Promise<void> {
      // no op
    },
    setEndpoint(payload: { endpoint?: string }): void {
      this.endpoint = payload.endpoint;
    },
    setMqttEndpoint(payload: { mqttEndpoint?: string }): void {
      this.mqttEndpoint = payload.mqttEndpoint;
    },
    setDarkMode(payload: { enabled: boolean }): void {
      this.darkMode = payload.enabled;
    },
  },
  persist: true,
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useConfigStore, import.meta.hot));
}
