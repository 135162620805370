import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import _isEmpty from "lodash/isEmpty";

import Club from "./model/Club";
import DividedGameGroup from "./model/DividedGameGroup";
import Game from "./model/Game";
import Rule from "./model/Rule";
import { Training } from "./model/Training";
import { UserId, User } from "./model/User";
import * as ruleHelpers from "./ruleHelper";

import type { ClubId } from "./model/Club";

dayjs.extend(customParseFormat);

export interface Page {
  pageNo: number;
  pageLimit: number;
}

export const getManagedClubs = async (instance: any): Promise<Array<Club>> => {
  const result = await instance.get(`/bus/admin/adminClubList`, {}, true);
  const data = _isEmpty(result.data.data) ? [] : result.data.data;
  return data.map((clubData) => new Club(clubData));
};

export const getClubMembers = async (instance: any, clubId: ClubId): Promise<Array<User>> => {
  const result = await instance.get(`/bus/admin/clubUserList/${clubId}`, {}, true);
  const rawMembers = _isEmpty(result.data.data) ? [] : result.data.data;
  return rawMembers.map((d) => new User(d));
};

export const kickClubMember = async (instance: any, data: { clubId: ClubId; userId: UserId }): Promise<void> => {
  await instance.post(`/bus/admin/kickClubUser`, data, true);
};

export type RuleId = string;

export class DailyEventRule {
  rawData: Record<string, any>;

  startTime: Dayjs | null;

  rules: Array<Rule>;

  constructor(rawData: Array<Record<string, any>>) {
    this.rawData = rawData;
    this.rules = rawData.map((raw) => new Rule(raw));
    this.startTime = null;
    const startTime = rawData?.[0]?.startTime;
    if (startTime) {
      this.startTime = startTime.includes("T") ? dayjs(startTime) : dayjs(startTime, "HH:mm:ss");
    }
  }

  setTime = (time: string, format: string): void => {
    this.startTime = dayjs(time, format);
  };
}

export const getDailyEventRule = async (
  instance: any,
  { clubId, ruleName }: { clubId: ClubId; ruleName: string },
): Promise<DailyEventRule> => {
  const gameTypeColumn = ruleHelpers.rule.columns.find((column) => column.backendKey === "gameType");
  const gameType = gameTypeColumn?.backendMapping?.[ruleName];
  const result = await instance.get(`/bus/rule/list`, { clubId, gameType }, true);
  const rules = _isEmpty(result.data.data) ? [] : result.data.data;
  return new DailyEventRule(rules);
};

export const setDailyEventRule = async (
  instance: any,
  { rules }: { rules: Array<Record<string, any>> },
): Promise<void> => {
  await instance.post(`/bus/rule/edit`, rules, true);
};

export const switchEvent = async (
  instance: any,
  { clubId, ruleName, openFlag }: { clubId: ClubId; ruleName: string; openFlag: number },
): Promise<void> => {
  const busTypeColumn = ruleHelpers.rule.columns.find((column) => column.backendKey === "gameType");
  const busType = busTypeColumn?.backendMapping?.[ruleName];
  await instance.post(`/bus/switch/edit`, { clubId, busType, openFlag }, true);
};

export interface Switch {
  busName: string;
  busType: number;
  clubId: number;
  createTime: string;
  openFlag: number;
}

export const getSwitchList = async (instance: any, { clubId }: { clubId: ClubId }): Promise<Switch[]> => {
  const busTypeColumn = ruleHelpers.rule.columns.find((column) => column.backendKey === "gameType");
  const backendMapping = busTypeColumn?.backendMapping ?? {};
  const busTypeList = Object.values(backendMapping);
  const res = await instance.post(`/bus/switch/getSwitchList`, { clubId, busTypeList }, true);
  const list: Switch[] = res?.data?.data ?? [];
  const switchList = list.map((element) => {
    let busName = "";
    // eslint-disable-next-line no-restricted-syntax
    for (const key in backendMapping) {
      if (backendMapping[ key ] === element.busType) {
        busName = key;
      }
    }
    return {
      ...element,
      busName,
    };
  });
  return switchList;
};

export interface GameGroup {
  bow: number;
  sex: number;
  distance: number;
  target: number;
  roundCount: number;
  groupCount: number;
  arrowGroup: number;
  playerGroup: number;
}

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
interface IGame {
  name: string;
  address: string;
  clubId: number;
  gameType: number;
  startTime: string;
  signStartTime: string;
  gameGroupList: Array<GameGroup>;
  publicFlag: number;
}

export const createRegularMatch = async (instance: any, params: IGame): Promise<void> => {
  await instance.post(`/bus/game/create`, params, true);
};

export interface GamePage {
  list: Array<Game>;
  total: number;
  current: number;
}

export const gamePage = async (
  instance: any,
  params: {
    clubId: ClubId;
    gameTypeList: number[];
    statusList?: number[];
    webShowStartTime?: string;
    webShowEndTime?: string;
  } & Page,
): Promise<GamePage> => {
  const result = await instance.post("/cus/game/gamePage", params, true);
  const list = _isEmpty(result.data.data) ? { list: [], total: 0, current: 1 } : result.data.data;
  list.list = list?.list?.map((data) => new Game(data)) || [];
  return list;
};

export const getAllGameRecords = async (instance: any, params: Page): Promise<GamePage> => {
  const result = await instance.post("/bus/game/getAllGameRecords", params, true);
  const list = _isEmpty(result.data.data) ? { list: [], total: 0, current: 1 } : result.data.data;
  list.list = list?.list?.map((data) => new Game(data)) || [];
  return list;
};

export interface TrainingPage {
  list: Array<Training>;
  total: number;
  current: number;
}

export const getAllTrainingRecords = async (instance: any, params: Page): Promise<TrainingPage> => {
  const result = await instance.post("/bus/game/getAllTrainingRecords", params, true);
  const list = _isEmpty(result.data.data) ? { list: [], total: 0, current: 1 } : result.data.data;
  list.list = list?.list?.map((data) => new Training(data)) || [];
  return list;
};

export const dividedGameGroupList = async (instance: any, gameId: string): Promise<DividedGameGroup> => {
  const result = await instance.get(`/cus/game/dividedGameGroupList/${gameId}`, {}, true);
  const rules = _isEmpty(result.data.data) ? [] : result.data.data;
  return rules.map((element) => new DividedGameGroup(element));
};

export const groupControl = async (
  instance: any,
  params: { gameGroupId: number; controlStatus: string },
): Promise<any> => {
  const result = await instance.post("/bus/game/groupControl", params, true);
  const list = _isEmpty(result.data.data) ? [] : result.data.data;
  return list;
};

export const initKnockoutInfo = async (instance: any, params: { gameGroupId: number }): Promise<any> => {
  let code = -1;
  let message = "";
  await instance
    .post(`/bus/game/initKnockoutInfo/${params.gameGroupId}`, {}, true)
    .then((res) => {
      code = 200;
      message = res.data.message || "生成成功";
    })
    .catch((e) => {
      code = -1;
      message = e.message;
    });
  return {
    code,
    message,
  };
};
