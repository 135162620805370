import _isEmpty from "lodash/isEmpty";

import { FileType } from "./model/FileType";
import User from "./model/User";

import type { ClubId } from "./model/club";

export type Token = string;

// export const login = async (code: string): Promise<Token> => {
//   logger.info(`Login("${code}")`);
//   const result = await Taro.request({
//     url: `${server}/cus/login/doLogin`,
//     method: "POST",
//     data: { code },
//     dataType: "json",
//     cache: "no-cache",
//   });
//   logger.info(result);
//   return result.data.data.jwt;
// };

export const getProfile = async (instance: any, params: { userId?: string }): Promise<User | null> => {
  const result = await instance.get(`/cus/user/getProfile`, params, true);
  const data = result.data.data;
  if (_isEmpty(data)) return null;
  return new User(data);
};

export const setProfile = async (instance: any, data: any): Promise<void> => {
  await instance.post(`/cus/user/setProfile`, data, true);
};

export const bindClub = async (instance: any, clubId: ClubId): Promise<void> => {
  await instance.post(`/cus/user/bindClub/${clubId}`, {}, true);
};
export const unBindClub = async (instance: any): Promise<void> => {
  await instance.post(`/cus/user/unbind/`, {}, true);
};

export const setPhoneNumber = async (instance: any, data: any): Promise<string> => {
  const result = await instance.post(`/cus/user/getWxPhoneNum`, data, true);
  return result.data.data;
};

export const updateInfo = async (instance: any, data: {realName: string | null; sex: number | null}): Promise<void> => {
  await instance.post(`/cus/user/updateInfo`, data, true);
};

export const getSubscriptionExpirationDateIso = async (instance: any, data?: {}): Promise<void> => {
  const res = await instance.post(`/cus/user/expTime`, data, true);
  return res.data?.data;
};

export const redeem = async (instance: any, option: { redeemCode: string }): Promise<{
  message: string;
  code: string;
}> => {
  let message = "";
  let code = "0";
  try {
    const res = await instance.post(`/cus/redeemCode/redeem`, option, true);
    message = res.message || "兑换成功";
    code = "0";
  } catch (error) {
    message = error.message || "兑换失败";
    code = "-1";
  }
  return { message, code };
};

export const claimFreeTrial = async (instance: any): Promise<void> => {
  await instance.post("/cus/redeemCode/experience", {}, true);
};

export const wechatWeblogin = async (
  instance: any,
  params: { code: string },
): Promise<Token> => {
  const result = await instance.post(`/cus/login/webLogin`, params, false);
  return result.data.data.jwt;
};

export const upload = async (
  instance: any,
  params: {
    fileType: FileType;
    file: File;
    userId: string;
  },
  extra: any,
): Promise<Token> => {
  const result = await instance.post(`/cus/file/upload`, {
    ...params,
    businessId: params.userId,
  }, true, extra);
  return result.data.data;
};
