<template>
  <template v-if="!slots.boundary">
    <slot v-if="!hasError$"></slot>
    <template v-else>
      <a-space direction="vertical">
        <a-alert type="error">
          <template #title>
            <div>系统错误</div>
            <div>
              {{ err$?.message ?? `Unknown` }}
            </div>
          </template>
          {{ err$?.stack }}
        </a-alert>
        <view style="display: block; text-align: center;">
          <a-button status="warning" @click="clearError">尝试忽略错误并继续</a-button>
        </view>
      </a-space>
    </template>
  </template>
  <slot
    v-else
    name="boundary"
    :has-error="hasError$"
    :error="err$"
    :info="info$"
  ></slot>
</template>

<script setup lang="ts">
import {
  ref, onErrorCaptured, useSlots, withDefaults, defineProps, defineEmits,
} from "vue";

export interface VErrorBoundaryProps {
  onError?: Function;
  stopPropagation?: boolean;
}
const props = withDefaults(defineProps<VErrorBoundaryProps>(), {
  onError: () => { /* empty */ },
  stopPropagation: false,
});
const emits = defineEmits([ "error-captured" ]);
const hasError$ = ref(false);
const err$ = ref<Error | null>(null);
const info$ = ref("");
const slots = useSlots();
if (!slots.default && !slots.boundary) {
  console.warn("ErrorBoundary component must have child components.");
}
onErrorCaptured((error: Error, vm, info: string): boolean => {
  hasError$.value = true;
  err$.value = error;
  info$.value = info;
  props?.onError?.(error, vm, info);
  emits("error-captured", { error, vm, info });
  console.error(error);
  return false;
});

const clearError = () => {
  hasError$.value = false;
  err$.value = null;
};
</script>
