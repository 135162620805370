import { createPinia } from "pinia";
import { PiniaLogger } from "pinia-logger";
import { createPersistedState } from "pinia-plugin-persistedstate";

import logger from "@/utils/logger";

const pinia = createPinia();

pinia.use(PiniaLogger({
  expanded: true,
  disabled: process.env.NODE_ENV === "production",
}));

pinia.use(createPersistedState({
  afterRestore: ({ store }) => {
    logger.log(`Store [${store.$id}] rehydrated`);
  },
}));

export default pinia;
