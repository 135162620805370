import { useUserStore } from "@/store/user";

const checkAuth = (authCode: Array<number>): boolean => {
  if (!authCode.length) {
    return true;
  }
  const role = useUserStore().role;
  if (!role) {
    return false;
  }
  return authCode.includes(role);
};

export { checkAuth };
