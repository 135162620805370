// @ts-ignore
import mqtt from "mqtt/dist/mqtt.min";

import { defaultEndpoint } from "./endpoint";

const TIMEOUT = 5000;

let timer: any;
const queue: Array<() => Promise<void> | void> = [];

const schedule = (newTask: () => Promise<void> | void): void => {
  queue.push(newTask);

  if (!timer) {
    timer = setInterval(async () => {
      if (queue.length === 0) {
        clearInterval(timer);
        timer = null;
        return;
      }
      const task = queue.splice(0, 1)[ 0 ];
      await task();
    }, TIMEOUT + 1000);
  }
};

const clear = (): void => {
  clearInterval(timer);
  timer = null;
  while (queue.length) { queue.pop(); }
};

const options = {
  keepalive: 5,
  connectTimeout: TIMEOUT,
};

export const createInstance = ({
  getDefaultEndpoint,
  getEndpoint,
}: {
  getDefaultEndpoint: () => string;
  getEndpoint?: () => string | null;
}): mqtt => {
  let endpoint = getDefaultEndpoint();
  try {
    endpoint = getEndpoint?.() ?? endpoint;
  } catch (e) {
    // Ignore error
  }
  if (endpoint == null) throw new Error("Unable to connect mqtt. Server is null.");
  const client = mqtt.connect(endpoint, options);

  schedule(
    // Empty block for mqtt connect.
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    (): void => {},
  );

  client.connect = (): void => {
    schedule(() => {
      // if (client.connected || client.reconnecting) return;
      // client.reconnect();
    });
  };

  client.disconnect = (): void => {
    clear();
    // client.end();
  };

  return client;
};
