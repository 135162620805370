export class MatchKnockoutMatchUpTargetNode {
  rawData: Record<string, any>;

  nthFinal: number;

  seq: number;

  fullPositions: Array<string>;

  constructor(rawData: Record<string, any>) {
    this.rawData = rawData;

    this.nthFinal = this.rawData.finalRound;
    this.seq = this.rawData.seq;
    this.fullPositions = this.rawData.knockoutTargetItemList
    ?.sort((a, b) => a.seq - b.seq)
    ?.map((it) => (![ null, undefined ].includes(it?.targetNo)
      ? `${it.targetNo}${it.targetLocation || ""}` : "")) ?? [];
  }
}

export default MatchKnockoutMatchUpTargetNode;
