import _isEmpty from "lodash/isEmpty";
import { useRoute } from "vue-router";

import router from "@/router";
import { useTelemetryStore } from "@/store/telemetry";
import { EventType } from "@ac/services/telemetry";

import type { Event } from "@ac/services/telemetry";

const getPrefix = (): string => "@ac/website/app";

const getPathAndOptions = (): { path: string; options: any } => {
  const currentPage = router.currentRoute.value;
  const path = `${getPrefix()}/${currentPage.path}`.replace(/\/{2,}/g, "/");
  return { path, options: currentPage.query };
};

export const logPageView = async (): Promise<void> => {
  const telemetryStore = useTelemetryStore();
  const { path, options } = getPathAndOptions();

  const event: Event = {
    event: EventType.PAGE_VIEW,
    path,
    metadata: {
      params: _isEmpty(options) ? undefined : options,
    },
  };
  try {
    telemetryStore.logEvent({ event });
  } catch (e) {
    console.error(e);
  }
};

export const logClick = async (buttonId: string, params?: any): Promise<void> => {
  const telemetryStore = useTelemetryStore();
  const route = useRoute();
  console.log(route);
  const { path } = getPathAndOptions();

  const event: Event = {
    event: EventType.CLICK,
    path: `${path}@${buttonId}`,
    metadata: {
      params: _isEmpty(params) ? undefined : params,
    },
  };
  try {
    telemetryStore.logEvent({ event });
  } catch (e) {
    console.error(e);
  }
};
