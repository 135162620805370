import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import _flow from "lodash/flow";
import __keyBy from "lodash/fp/keyBy";
import __mapValues from "lodash/fp/mapValues";
import __toArray from "lodash/fp/toArray";

dayjs.extend(customParseFormat);

export const getMobileConfigs = async (
  instance: any,
): Promise<string> => {
  const result = await instance.get("/cus/killSwitch/all", false);
  return _flow(
    __toArray,
    __keyBy((it) => it.switchKey),
    __mapValues((it) => {
      try {
        return JSON.parse(it.switchValue);
      } catch (e) {
        return undefined;
      }
    }),
  )(result.data.data);
};
