import { transcodeOssUrl } from "../oss";

export type ClubId = string | number;

export class Club {
  rawData: Record<string, any>;

  clubId: ClubId;

  address: string;

  arrowRoadCount: string;

  arrowRoadDistance: string;

  fullName: string;

  introduction: string;

  logoUrl?: string;

  memberCount: string;

  pictureUrls: Array<string>;

  tel: string;

  phone: string;

  shortName: string;

  latitude: number;

  longitude: number;

  constructor(rawData: Record<string, any>) {
    this.rawData = rawData;

    this.clubId = rawData.clubId;
    this.address = rawData.address;
    this.arrowRoadCount = `${rawData.arrowRoadCount}条箭道`;
    this.arrowRoadDistance = rawData.arrowRoadDistance;
    this.fullName = rawData.fullName;
    this.introduction = rawData.introduction;
    this.logoUrl = transcodeOssUrl(rawData.logo);
    this.memberCount = rawData.memberCount;
    this.pictureUrls = rawData?.picUrl?.split(",").map(transcodeOssUrl);
    this.tel = rawData.tel;
    this.phone = rawData.phone;
    this.shortName = rawData.shortName;
    this.latitude = rawData.latitude;
    this.longitude = rawData.longitude;
  }
}

export default Club;
