export const MQTT_ENDPOINT_WXS = "wxs://www.archeryclub.cn/mqtt";

export const MQTT_ENDPOINT_WXS_DEV = "wxs://dev.archeryclub.cn/mqtt";

export const MQTT_ENDPOINT_WSS = "wss://www.archeryclub.cn/mqtt";

export const MQTT_ENDPOINT_WSS_DEV = "wss://dev.archeryclub.cn/mqtt";

export const DEFAULT_ENDPOINT_WXS = process.env.NODE_ENV !== "production" ? MQTT_ENDPOINT_WXS_DEV : MQTT_ENDPOINT_WXS;

export const DEFAULT_ENDPOINT_WSS = process.env.NODE_ENV !== "production" ? MQTT_ENDPOINT_WSS_DEV : MQTT_ENDPOINT_WSS;
