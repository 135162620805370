export const OSS_PROD = "https://archery-club-prod.oss-cn-zhangjiakou.aliyuncs.com";
export const OSS_DEV = "https://archery-club-prod.oss-cn-zhangjiakou.aliyuncs.com";
// "https://archery-club-dev.oss-cn-wulanchabu.aliyuncs.com";

let ossEndpoint = OSS_PROD;

export const setDevOssEndpoint = (): void => {
  ossEndpoint = OSS_DEV;
};

export const transcodeOssUrl = (rawUrl: string | undefined): string | undefined => {
  if (!rawUrl) return rawUrl;
  if (rawUrl.startsWith("http")) return rawUrl;
  if (rawUrl.startsWith("/")) return `${ossEndpoint}${rawUrl}`;
  return `${ossEndpoint}/${rawUrl}`;
};
