export class MixedMatchGroup {
  rawData: Record<string, any>;

  firstPlayerName: string;

  firstSignId: number;

  gameGroupId: number;

  gameGroupName: string;

  gameGroupCustomName: string;

  gameId: number;

  id: number;

  mixedGroupName: string;

  secondPlayerName: string;

  secondSignId: string;

  thirdPlayerName: string;

  thirdSignId: string;

  constructor(rawData: Record<string, any>) {
    this.firstPlayerName = rawData.firstPlayerName;
    this.firstSignId = rawData.firstSignId;
    this.gameGroupId = rawData.gameGroupId;
    this.gameGroupName = rawData.gameGroupName;
    this.gameGroupCustomName = rawData.gameGroupCustomName;
    this.gameId = rawData.gameId;
    this.id = rawData.id;
    this.mixedGroupName = rawData.mixedGroupName;
    this.secondPlayerName = rawData.secondPlayerName;
    this.secondSignId = rawData.secondSignId;
    this.thirdPlayerName = rawData.thirdPlayerName;
    this.thirdSignId = rawData.thirdSignId;
  }
}

export default MixedMatchGroup;
