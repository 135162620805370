import { transcodeOssUrl } from "../oss";

import { Sex } from "./User";

export enum StaffType {
  LEADER = 0, // 领队
  COACH = 1, // 教练
  LEADER_AND_COACH = 8, // 领队兼教练
  DOCTOR = 9, // 队医
  TEAM_STAFF = 20, // 参赛单位工作人员
  ARBITER = 2, // 仲裁
  REFEREE_CHIEF = 10, // 总裁判长
  REFEREE_VICE_CHIEF_AND_CHIEF = 11, // 副总裁判长兼裁判长
  REFEREE_VICE_CHIEF = 12, // 副裁判长
  STARTER = 5, // 发令长
  STARTER_VICE = 13, // 副发令长
  HEAD_CHOREOGRAPHER = 4, // 编排长
  HEAD_CHOREOGRAPHER_VICE = 14, // 副编排长
  UMPIRE = 6, // 裁判员
  SCORE_KEEPER = 15, // 记分员
  REFEREE = 3, // 裁判长
  STAFF_MEMBER = 7, // 工作人员
  ATHLETE = 100, // 选手
}

export const staffTypeList: Array<{ label: string; value: StaffType }> = [
  {
    label: "选手",
    value: StaffType.ATHLETE,
  },
  {
    label: "领队",
    value: StaffType.LEADER,
  },
  {
    label: "教练",
    value: StaffType.COACH,
  },
  {
    label: "领队兼教练",
    value: StaffType.LEADER_AND_COACH,
  },
  {
    label: "队医",
    value: StaffType.DOCTOR,
  },
  {
    label: "随队工作人员",
    value: StaffType.TEAM_STAFF,
  },
  {
    label: "仲裁",
    value: StaffType.ARBITER,
  },
  {
    label: "总裁判长",
    value: StaffType.REFEREE_CHIEF,
  },
  {
    label: "副总裁判长兼裁判长",
    value: StaffType.REFEREE_VICE_CHIEF_AND_CHIEF,
  },
  {
    label: "副裁判长",
    value: StaffType.REFEREE_VICE_CHIEF,
  },
  {
    label: "发令长",
    value: StaffType.STARTER,
  },
  {
    label: "副发令长",
    value: StaffType.STARTER_VICE,
  },
  {
    label: "编排长",
    value: StaffType.HEAD_CHOREOGRAPHER,
  },
  {
    label: "副编排长",
    value: StaffType.HEAD_CHOREOGRAPHER_VICE,
  },
  {
    label: "裁判员",
    value: StaffType.UMPIRE,
  },
  {
    label: "记分员",
    value: StaffType.SCORE_KEEPER,
  },
  {
    label: "裁判长",
    value: StaffType.REFEREE,
  },
  {
    label: "工作人员",
    value: StaffType.STAFF_MEMBER,
  },
];

export const getStaffTypeLabel = (value: StaffType): string => {
  const item = staffTypeList.find((el) => el.value === value);
  return item?.label || "";
};

class Staff {
  rawData: Record<string, any>;

  id: string;

  matchId: string;

  userId: string;

  name: string;

  staffType: StaffType;

  sex: Sex;

  phone: string;

  idNo: string;

  idPhoto: string | undefined;

  teamId: string;

  teamName: string;

  teamNameOrClubName: string;

  get sexFmt(): string {
    return this.sex === Sex.MAN ? "男" : "女";
  }

  get staffTypeFmt(): string {
    return getStaffTypeLabel(this.staffType);
  }

  get confirmationCode(): string {
    return [ this.matchId ?? "X", this.userId ?? "X", this.id ?? "X" ].join("-");
  }

  static decodeConfirmationCode(code: string):
    | {
        matchId: string;
        userId: string;
        id: string;
      }
    | undefined {
    const regex = /^(\d+)-(\d+)-(\d+)-(\d+)$/g;
    const regexResult = regex.exec(code);
    if (regexResult == null) return undefined;
    return {
      matchId: regexResult[ 1 ],
      userId: regexResult[ 2 ],
      id: regexResult[ 3 ],
    };
  }

  get confirmationQrDataObject(): { [key: string]: string | undefined } {
    return {
      matchId: this.matchId ?? undefined,
      userId: this.userId ?? undefined,
      id: this.id ?? undefined,
    };
  }

  static decodeConfirmationQrData(code: string):
    | {
        matchId: string;
        userId: string;
        id: string;
      }
    | undefined {
    try {
      const data = JSON.parse(code);
      if (data.matchId == null || data.userId == null || data.id == null) {
        return undefined;
      }
      return data;
    } catch (e) {
      return undefined;
    }
  }

  constructor(rawData: Record<string, any>) {
    this.rawData = rawData;
    this.id = rawData.id;
    this.matchId = rawData.gameId;
    this.userId = rawData.userId;
    this.name = rawData.name;
    this.staffType = rawData.staffType;
    this.sex = rawData.sex;
    this.phone = rawData.phone;
    this.idNo = rawData.idNo;
    this.idPhoto = transcodeOssUrl(rawData.idPhoto);
    this.teamId = rawData.teamId;
    this.teamName = rawData.teamName;
    this.teamNameOrClubName = rawData.teamName || rawData.clubFullName;
  }
}

export default Staff;
