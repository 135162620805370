export const DEV_BASE = "https://dev.archeryclub.cn";

export const PRODUCTION_BASE = "https://www.archeryclub.cn";

export const defaultEndpoint = process.env.NODE_ENV !== "production" ? DEV_BASE : PRODUCTION_BASE;

export const DEFAULT_BASE = defaultEndpoint;

export const CUSTOMER_LOGIN_ENDPOINT = "/cus/login/doLogin";

export const BUSINESS_LOGIN_ENDPOINT = "/bus/login/doLogin";

export default { defaultEndpoint };
