import dayjs from "dayjs";

import Game from "./Game";

import type { ScoreMode } from "./Game";

export class Match extends Game {
  get params(): MatchParams {
    return {
      name: this.name,
      province: this.province,
      city: this.city,
      address: this.address,
      startTime: dayjs(this.startTime).toDate(),
      endTime: dayjs(this.endTime).toDate(),
      registrationStartTime: dayjs(this.registrationStartIsoTimestamp).toDate(),
      registrationEndTime: dayjs(this.registrationDeadlineIsoTimestamp).toDate(),
      public: this.openToPublic,
      requireTeam: this.needTeam,
      multipleSign: this.multipleSign,
      scoreMode: this.scoreMode,
      needPhone: this.needPhone,
      needId: this.needId,
      officialFlag: this.officialFlag,
      mixedGroupFlag: this.mixedGroupFlag,
      knockoutLimit: this.knockoutLimit,
      playerLimit: this.playerLimit,
      qualifyingExtraFlag: this.qualifyingExtraFlag,
      needLeader: this.needLeader,
      host: this.host,
      execute: this.execute,
      guidanceUnit: this.guidanceUnit,
      contact: this.contact,
      webShowStartTime: dayjs(this.webShowStartTime).toDate(),
      webShowEndTime: dayjs(this.webShowEndTime).toDate(),
    };
  }
}

export type MatchParams = {
  name?: string;
  province?: string;
  city?: string;
  address?: string;
  startTime?: Date;
  endTime?: Date;
  registrationStartTime?: Date;
  registrationEndTime?: Date;
  public?: boolean;
  requireTeam?: boolean;
  multipleSign?: boolean;
  scoreMode?: ScoreMode;
  needPhone?: boolean;
  needId?: boolean;
  needLeader?: boolean;
  officialFlag?: boolean;
  mixedGroupFlag?: boolean;
  knockoutLimit?: number;
  playerLimit?: number;
  qualifyingExtraFlag?: boolean; // 排位赛附加赛
  host?: string;
  execute?: string;
  guidanceUnit?: string;
  contact?: string;
  webShowStartTime?: Date;
  webShowEndTime?: Date;
}

const backendTimestampFormat = "YYYY-MM-DD HH:mm:ss";

export const mapMatchParamsToServerParams = (matchParams: MatchParams): any => ({
  name: matchParams.name,
  province: matchParams.province,
  city: matchParams.city,
  address: matchParams.address,
  startTime: !matchParams.startTime ? undefined : dayjs(matchParams.startTime)?.format(backendTimestampFormat),
  // endTime: !matchParams.endTime ? undefined : dayjs(matchParams.endTime)?.format(backendTimestampFormat),
  signStartTime: !matchParams.registrationStartTime
    ? undefined : dayjs(matchParams.registrationStartTime)?.format(backendTimestampFormat),
  signEndTime: !matchParams.registrationEndTime
    ? undefined : dayjs(matchParams.registrationEndTime)?.format(backendTimestampFormat),
  publicFlag: matchParams.public ? 1 : 0,
  needTeam: matchParams.requireTeam ? 1 : 0,
  multipleSign: matchParams.multipleSign ? 1 : 0,
  scoreMode: matchParams.scoreMode,
  needPhone: matchParams.needPhone ? 1 : 0,
  needId: matchParams.needId ? 1 : 0,
  needLeader: matchParams.needLeader ? 1 : 0,
  officialFlag: matchParams.officialFlag ? 1 : 0,
  mixedGroupFlag: matchParams.mixedGroupFlag ? 1 : 0,
  knockoutLimit: matchParams.knockoutLimit,
  playerLimit: matchParams.playerLimit,
  qualifyingExtraFlag: matchParams.qualifyingExtraFlag ? 1 : 0,
  host: matchParams.host,
  execute: matchParams.execute,
  guidanceUnit: matchParams.guidanceUnit,
  contact: matchParams.contact,
  webShowStartTime: !matchParams.webShowStartTime
    ? undefined : dayjs(matchParams.webShowStartTime)?.format(backendTimestampFormat),
  webShowEndTime: !matchParams.webShowEndTime
    ? undefined : dayjs(matchParams.webShowEndTime)?.format("YYYY-MM-DD 23:59:59"),
});

export default Match;
