import _isFinite from "lodash/isFinite";

import { KnockoutRule } from "../model/MatchGroup";

export const getNthFinalsFromMaxNthFinal = (maxNthFinal: number, splitFinal = true): Array<string> => {
  if (maxNthFinal <= 0) return [];
  const length = Math.floor(Math.log2(maxNthFinal)) + 1;
  const array = Array.from({ length }, (_, index) => String(2 ** (length - index - 1)));
  // 决赛需要特殊处理 分为金牌决赛和铜牌决赛
  if (splitFinal) {
    array.push("0");
  }
  return array;
};

export const getNthFinalsFromNumberOfParticipant = (numberOfParticipant: number, splitFinal = true): Array<string> =>
  getNthFinalsFromMaxNthFinal(numberOfParticipant - 1, splitFinal);

export const getHumanReadableNthFinal = (nthFinal: string | number, splitFinal = true): string => {
  if (splitFinal) {
    if (String(nthFinal) === "0") {
      return "金牌决赛";
    }
    if (String(nthFinal) === "1") {
      return "铜牌决赛";
    }
  } else if (String(nthFinal) === "1") {
    return "决赛";
  }
  if (String(nthFinal) === "2") {
    return "半决赛";
  }
  if (_isFinite(Number(nthFinal))) {
    return `1 / ${nthFinal}`;
  }
  return `未知`;
};

export const getMaximumNumberOfParticipantForNthFinal = (nthFinal: string | number): number => {
  const nthFinalWithGoldMitigation = Math.max(Number(nthFinal), 1);
  return 2 * nthFinalWithGoldMitigation;
};

export const getNumberOfParticipantsForKnockoutRound = (
  nthFinal: number,
  groupNumberOfParticipants: number,
): number => {
  const maximumNumberOfParticipant = getMaximumNumberOfParticipantForNthFinal(nthFinal);
  if (maximumNumberOfParticipant > groupNumberOfParticipants) {
    return maximumNumberOfParticipant - (maximumNumberOfParticipant - groupNumberOfParticipants) * 2;
  }
  return maximumNumberOfParticipant;
};

export const mapMaximumNthFinalWithRule = (
  nthFinal: number,
  rule: KnockoutRule = KnockoutRule.STANDARD,
): number => {
  if (rule === KnockoutRule.QUANNENG) {
    if (nthFinal === 48) {
      return 64;
    } if (nthFinal === 24) {
      return 32;
    } if (nthFinal === 12) {
      return 16;
    }
  }
  return nthFinal;
};

export const getPossibleNthFinalsWithRule = (
  nthFinal: number,
  rule: KnockoutRule = KnockoutRule.STANDARD,
): Array<number> => {
  if (rule === KnockoutRule.QUANNENG) {
    if (nthFinal === 64) {
      return [ 64, 48 ];
    } if (nthFinal === 32) {
      return [ 32, 24 ];
    } if (nthFinal === 16) {
      return [ 16, 12 ];
    }
  }
  return [ nthFinal ];
};

export const preMapNthFinalAndSeqToNthFinal = (nthFinal: number, seq: number): number =>
  // nthFinal === 0 -> 金牌决赛
  (nthFinal === 1 && seq === 1 ? 0 : nthFinal);

export const afterMapHumanReadableNthFinalWithRule = (
  humanReadableNthFinal: string,
  rule: KnockoutRule = KnockoutRule.STANDARD,
): string => {
  if (rule === KnockoutRule.QUANNENG) {
    if (humanReadableNthFinal === "1 / 64") {
      return "1 / 48";
    } if (humanReadableNthFinal === "1 / 32") {
      return "1 / 24";
    } if (humanReadableNthFinal === "1 / 16") {
      return "1 / 12";
    }
  }
  return humanReadableNthFinal;
};
