import Rule from "./Rule";

export class GameRule {
  rawData: Record<string, any>;

  rules: Array<Rule>

  constructor(rawData: Array<Record<string, any>>, gameType: number) {
    this.rawData = rawData;
    this.rules = rawData.map((raw) => new Rule({ ...raw, gameType }));
  }
}

export default GameRule;
